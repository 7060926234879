import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Badge from '@material-ui/core/Badge'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({

    OrderListItem:{
        padding : '10px 15px',
        marginBottom : '10px',
        marginTop : '10px'
    },
    orderItemsContainer : {
        borderTop : '1px solid #f9f9f9'
    },
    orderItemsText : {
        fontSize : 13
    },
    chip : {
        textTransform : 'uppercase',
        padding : '2px 10px',
        borderRadius : 15
    },
    orderStatus : {
        textTransform : 'uppercase',
        whiteSpace : 'nowrap',
    }

}));

export default function ShipmentListItem( props ) {
  const classes = useStyles();

  const { orderDetails } = props ;
  
  const  { courier_name, id , awb_code , woo_id , charges, status} = orderDetails ;
  
  return (
    
        <Paper elevation={0} className={classes.OrderListItem} >
            <Badge color="secondary" badgeContent={status} className={classes.orderStatus} ></Badge>
            <Grid container spacing={3} >
                <Grid item xs={12}>
                    
                    <Grid container spacing={1} alignItems="center" >
                        <Grid item xs={1}>
                            <Checkbox />
                        </Grid>
                        <Grid item xs={2} alignItems="center" direction="row">
                            <a 
                            rel="noreferrer"
                            target="_blank" 
                            href={`https://shiprocket.co/tracking/${awb_code}`}
                            >
                                <Typography > #{id} </Typography>
                                <Typography > {courier_name} </Typography>
                            </a>
                        </Grid>
                        <Grid item xs={3} alignItems="center" direction="row">
                            <Chip label={awb_code} className={classes.chip} />
                        </Grid>
                        <Grid item xs={2} alignItems="center" direction="row">
                            <Typography > Freight : ₹{charges.freight_charges} </Typography>
                            <Typography > COD : ₹{charges.cod_charges} </Typography>
                        </Grid>
                        <Grid item xs={2} alignItems="center" direction="row">
                            <Link to={`/orders/${woo_id}`} >
                                <Typography > #{woo_id} </Typography>
                            </Link>
                        </Grid>
                        <Grid item xs={2} alignItems="center" direction="row">
                            <Typography > {status} </Typography>
                        </Grid>
                    </Grid>
                    
                    
                </Grid>

            </Grid> 
        </Paper>
    
  );
}