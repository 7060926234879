import React from "react";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import Button from '@material-ui/core/Button';



export default function DownloadLabel( props ) {
  
  const { id } = props ;

  return (
    <React.Fragment>
        <a href={"https://pok-backend-y3hbx.ondigitalocean.app/api/v1/orders/label/" + id } download>
        <Button variant="outlined" color="secondary" fullWidth >
            Download Label
        </Button>
        </a> 

    </React.Fragment>
  );
}