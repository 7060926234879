import React from "react";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { Grid, Typography } from "@material-ui/core";


function BookCourier( props ){
  const {
    courier_company_id,
    courier_name,
    rate,
    length,
    breath,
    height,
    weight,
    order_id 
  } = props ;

  const BOOK_COURIER = gql`
    query {
      bookNewCourier ( order_id : ${order_id}, length : ${length}, breath : ${breath}, height : ${height}, weight : ${weight}, courier_id : ${courier_company_id} ){
        woo_id
        order_id
        shipment_id
        status
        awb_code
        label_url
        courier_company_id
        courier_name
      }
    }
  `;

  const [
    bookNewCourier, 
    { loading, data }
  ] = useLazyQuery(BOOK_COURIER);

  if(loading){
    return(
      <CircularProgress />
    )
  }



  if(data && data.bookNewCourier){

    const courierDetails = data.bookNewCourier ;

    return(
      <Grid container spacing={5} alignItems="center" style={{ paddingBottom : 20}}>
        <Grid item xs={6}>
          <Typography variant="button" display="block" gutterBottom> Courier Name : {courierDetails.courier_name}</Typography>
          <Typography variant="button" display="block" gutterBottom> AWB No : {courierDetails.awb_code}</Typography>

        </Grid>
        <Grid item xs={6}>
          <Button variant="contained" color="primary" >
            <a href={`${courierDetails.label_url}`} style={{ color : '#fff'}} target='_blank' rel="noreferrer"  >
            Download Label
            </a>
          </Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={3} >
        <Grid item xs={6}>
          <Typography variant="button" display="block" gutterBottom> Courier Name : {courier_name}</Typography>
          <Typography variant="button" display="block" gutterBottom> Courier ID : {courier_company_id}</Typography>
          <Typography variant="button" display="block" gutterBottom> Rate : ₹{rate}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="button" display="block" gutterBottom> Length ( cm ): {length}</Typography>
          <Typography variant="button" display="block" gutterBottom> Breadth  ( cm ) : {breath}</Typography>
          <Typography variant="button" display="block" gutterBottom> Height ( cm ) : {height}</Typography>
          <Typography variant="button" display="block" gutterBottom> Weight  ( kg ) : {weight}</Typography>
        </Grid>
        <Grid item xs={12} >
          <div style={{ display : 'flex', justifyContent : 'center'}}>
          <Button variant="contained" color="primary" style={{ margin : '0 auto' }} onClick={() => bookNewCourier()}> Confirm Booking </Button>
          </div>
        </Grid>
        <Grid item xs={12}></Grid>
    </Grid>
  )
}


export default function BookCourierButton( props ) {
  const [open, setOpen] = React.useState(false);
  const [step, setStep] = React.useState(0);
  let [length, setLength] = React.useState(0);
  let [breath, setBreath] = React.useState(0);
  let [height, setHeight] = React.useState(0);
  let [weight, setWeight] = React.useState(0);

  let [rate, setRate] = React.useState(0);
  let [courierName, setCourierName] = React.useState(0);
  let [courierCompanyId, setCourierCompanyId] = React.useState(0);

  console.log(length);
  var  { id } = props ;
  id = parseInt(id);
  
  let lengthFloat = parseFloat(length);
  let breathFloat = parseFloat(breath);
  let heightFloat = parseFloat(height);
  let weightFloat = parseFloat(weight);


  const CHECK_COURIER = gql`
    query {
      checkCourierServiceablity ( order_id : ${id}, length : ${lengthFloat}, breath : ${breathFloat}, height : ${heightFloat}, weight : ${weightFloat} ){
        courier_name
        rate
        cod_charges
        courier_company_id
        estimated_delivery_days
      }
    }
  `;

  

  const [
    checkCourierServiceablity, 
    { loading, data }
  ] = useLazyQuery(CHECK_COURIER);
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const nextStep = () => {
    setStep( step + 1 );
  };
  const lastStep = () => {
    setStep( step - 1 );
  };


  const formSubmitted = ( event , data) => {
    event.preventDefault();
    checkCourierServiceablity() 
    setStep(1);
  }


  var courierpartners = [] 
  if(data)
    courierpartners = data.checkCourierServiceablity ; 

  return (
    <React.Fragment>
         <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Book New Couier #{id}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Stepper activeStep={step}>
                <Step key={`Box Details`} >
                  <StepLabel > Box Details </StepLabel>
                </Step>
                <Step key={`Select Courier`} >
                  <StepLabel > Select Courier </StepLabel>
                </Step>
                <Step key={`Book Coruier`} >
                  <StepLabel > Book Courier </StepLabel>
                </Step>
              </Stepper>
            </DialogContentText>

            { step === 0  && <form onSubmit={formSubmitted}>
              <div style={{ display : 'flex' , marginBottom: 15}}>
                <TextField
                  
                  id="outlined-error"
                  label="Length ( in cm )"
                  variant="outlined"
                  style={{
                    margin : 10 
                  }}
                  value={length}
                  onInput={ e=>setLength(e.target.value)}
                  required
                />
                <TextField
                  
                  id="outlined-error-helper-text"
                  label="Breath ( in cm )"
                  variant="outlined"
                  style={{
                    margin : 10 
                  }}
                  value={breath}
                  onInput={ e=>setBreath(e.target.value)}
                  required
                />
                <TextField
                  
                  id="outlined-error-helper-text"
                  label="Height ( in cm )"
                  variant="outlined"
                  style={{
                    margin : 10 
                  }}
                  value={height}
                  onInput={ e=>setHeight(e.target.value)}
                  required
                />
              </div>
              <div style={{ display : 'flex' , justifyContent : 'center'}}>
              <TextField
                
                id="outlined-error-helper-text"
                label="Weight ( in kg )"
                variant="outlined"
                value={weight}
                onInput={ e=>setWeight(e.target.value)}
                required
              />
            </div>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button  type={'submit'} color="primary">
                  Next
                </Button>
              </DialogActions>
            </form>
            }

            { step === 1  && <div>
              {loading && <p> Loading Courier Rates </p>}

              <List >
                {data && courierpartners.map(item => 
                <ListItem>
                  <ListItemText primary={item.courier_name} secondary={`₹${item.rate} | COD : ₹${item.cod_charges} | In ${item.estimated_delivery_days} Days`} />
                  <ListItemSecondaryAction>
                    <Button variant="contained" color="primary" onClick={() => {
                      setCourierCompanyId(item.courier_company_id);
                      setCourierName(item.courier_name);
                      setRate(item.rate);
                      nextStep();
                    }} > Book </Button>
                  </ListItemSecondaryAction>
                </ListItem>)}                
              </List> 
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={lastStep} color="primary">
                  Back
                </Button>
              </DialogActions>
            </div>
            }

            {step === 2 && <BookCourier order_id={id} courier_name={courierName} courier_company_id={courierCompanyId} rate={rate} length={lengthFloat} breath={breathFloat} height={heightFloat} weight={weightFloat} />}
          </DialogContent>
          
        </Dialog>

        {!data &&  
        <Button variant="outlined" fullWidth disableElevation color="secondary" onClick={() => handleClickOpen()}>
            Book Courier
        </Button>}

    </React.Fragment>
  );
}