import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router';
import LinearProgress from '@material-ui/core/LinearProgress';
import OrderDetails from '../../components/orderDetails';

const useStyles = makeStyles((theme) => ({
  ordersCountRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding : '30px 0px',
    background : '#fff'
  },
  root: {
    flexGrow: 1,
  },
  pagination : {
    float : 'right'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor : '#ffeee8',
    color: '#333',
  }
}));





class OrderPageComponent extends React.Component {
    constructor() {
        super();

        this.state = {
            orderStatus : 'all',
            page : 0
        }
    }

    
    render(){
        const { orderId } = this.props ;
        const classes = this.props.classes;
        return (
            
            <div className={classes.root}>
                {/* <LinearProgress /> */}
                
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <OrderDetails orderId={orderId} />
                    
                    </Grid>
                    <Grid item xs={12}>
                    </Grid>
                </Grid>
                
            </div>  
        );
    }
  
}


function OrderPage( data ) {
    
    const classes = useStyles();
    return (
        <OrderPageComponent classes={classes} orderId={data.match.params.id} />
    )
}

export default withRouter(OrderPage);