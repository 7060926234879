import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import OrderCount from '../../components/orderCount';
import ListLeads from '../../components/listLeads';
import { withRouter } from 'react-router'
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  ordersCountRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding : '30px 0px',
    background : '#fff'
  },
  root: {
    flexGrow: 1,
  },
  pagination : {
    float : 'right'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor : '#ffeee8',
    color: '#333',
  }
}));





class LeadsComponent extends React.Component {
    constructor() {
        super();
        

        this.state = {
            orderStatus : 'all',
            page : 0
        }
    }

    nextPage = () => {
        let { page } = this.state ;
        
        page = page + 1 ;

        this.setState({page});
    } 

    setPage = ( page = 1 ) => {
        
        page = page - 1 ;

        if(page >= 0 )
            this.setState({page});
    } 
    changePage = ( event , page ) => {
        
        this.setPage(page);

    }
    render(){
        
        const  { classes , status }= this.props ;
        let { orderStatus , page} = this.state ;

        if(status)
            orderStatus = status ;

        return (
            
            <div className={classes.root}>
                {/* <LinearProgress /> */}
                
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                            <Typography variant="h6" component="h6">
                                All Leads
                            </Typography>
                            </Grid>
                            <Grid item xs={6} justify="flex-end">
                                <Pagination count={page + 5} variant="outlined"  className={classes.pagnation} onChange={this.changePage} />
                            </Grid>
                        </Grid>
                    
                    </Grid>
                    <Grid item xs={12}>
                        <ListLeads status={orderStatus} page={page} />
                    </Grid>
                </Grid>
                
            </div>  
        );
    }
  
}


function Leads( data ) {
    const classes = useStyles();
    console.log(data.match.params);
    if(data.match.params.status != undefined){
        console.log('Showing Componetn with status');
        return  <LeadsComponent classes={classes} status={data.match.params.status} />
    }
    return  <LeadsComponent classes={classes} />
    
}

export default withRouter(Leads);