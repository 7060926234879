import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import { gql, useQuery } from '@apollo/client';
import OrderListItem from '../../orderListItem' ;
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { Input } from '@material-ui/core';
import { Group } from '@material-ui/icons';
import Drawer from '@material-ui/core/Drawer';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReplayIcon from '@material-ui/icons/Replay';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import HyperLocalPreview from '../hyperlocalPreview';

const useStyles = makeStyles((theme) => ({
    groupDate : {
        float : 'right',
        paddingBottom : 10
    },
    groupTitleWrap : {
        borderBottom : '1px solid #02042B',
        padding : '15px 0px',
        marginBottom : 30,
        textTransform : 'uppercase'
    }

}));


function hex2rgb(hex, opacity) {
    var h=hex.replace('#', '');
    h =  h.match(new RegExp('(.{'+h.length/3+'})', 'g'));
  
    for(var i=0; i<h.length; i++)
        h[i] = parseInt(h[i].length==1? h[i]+h[i]:h[i], 16);
  
    if (typeof opacity != 'undefined')  h.push(opacity);
  
    return 'rgba('+h.join(',')+')';
  }
  
function AddOrderToGroup( props) {
  let { name , meta  , hasDeliveryBoyAssigned , deliveryBoy , status } = props.group ;

  let [orderId, setOrderId] = React.useState('');

  let defaultShowAssignForm = true ;

  if(hasDeliveryBoyAssigned)
      defaultShowAssignForm = false ;


  return (
      <Grid container>
        
      <Grid container>
          <Grid item xs={12}>
          <Input placeholder='Order Id' fullWidth  value={orderId} onChange={(e) => setOrderId(e.target.value)} />
          </Grid>
          <Grid item xs={12}>
          <Button  fullWidth  onClick={async () => {
              
              const paymentLinkResponse = await axios.post(
              `https://pok-backend-y3hbx.ondigitalocean.app/api/v1/groups/order/add`,
              { 
                  data :  
                  { name : name , orderId : orderId  }

              }).catch((error) => {
              console.log({error});
              alert('Failed To Add Order To Group');          
              });
              alert('Add Order To Group');          

          }}> ADD ORDER </Button>
          </Grid>
      </Grid>
      </Grid>
  )
}

function AsignDeliveryBoy( props) {
let { name , meta  , hasDeliveryBoyAssigned , deliveryBoy , status  } = props.group ;

let [amountPaid, setAmountPaid] = React.useState('');
let [deliverBoyName, setDeliverBoyName] = React.useState('');
let [deliverBoyNo, setDeliverBoyNo] = React.useState('');

let defaultShowAssignForm = true ;

if(hasDeliveryBoyAssigned)
    defaultShowAssignForm = false ;

let [showAssignForm, setShowAssignFrom] = React.useState(defaultShowAssignForm);

return (
    <Grid container>
    {hasDeliveryBoyAssigned && !showAssignForm && <div>
        <Typography variant='overline' fullWidth>Delivery By</Typography>
        <Typography variant='body1' >{deliveryBoy.name}</Typography>
        <Typography variant='body1' >{deliveryBoy.mobile}</Typography>
        <Typography variant='caption' onClick={() => {
        setShowAssignFrom(true);
        }}>Edit</Typography>
    </div>}
    {showAssignForm && <Grid container>
        <Grid item xs={12}>
        <Input placeholder='Amount Paid' fullWidth type='number'  value={amountPaid} onChange={(e) => setAmountPaid(e.target.value)} />
        </Grid>
        <Grid item xs={12}>
        <Input placeholder='Name' fullWidth  value={deliverBoyName} onChange={(e) => setDeliverBoyName(e.target.value)} />
        </Grid>
        <Grid item xs={12}>
        <Input placeholder='Mobile No' fullWidth  value={deliverBoyNo} onChange={(e) => setDeliverBoyNo(e.target.value)}  />
        </Grid>
        <Grid item xs={12}>
        <Button  fullWidth  onClick={async () => {
            
            await axios.post(
            `https://pok-backend-y3hbx.ondigitalocean.app/api/v1/groups/assign`,
            { 
                data :  
                { groupName : name , amountPaid :  amountPaid ,  dboyName : deliverBoyName , dboyNo : deliverBoyNo  }

            }).catch((error) => {
                console.log({error});
                alert('Failed To Assign Delivery Boy');          
            });
            props.onReload();

        }}> Update Delivery Boy</Button>
        </Grid>
    </Grid>}
    </Grid>
)
}

export default function GroupItem({ group, onReload }) {

    const styles = useStyles();

    const [wefastEstimate , setWefastEstimate] = useState(0);
    const [showHyperLocalBookingPreview , setShowHyperLocalBookingPreview] = useState(false);
    const [activeWefastGroupId , setActiveWefastGroupId] = useState(0);
    const [calculatingWefastTotal , setCalculatingWefastTotal] = useState(false);
    const [markingGroupOutForDelivery , setGroupOutForDelivery] = useState(false);
    

    return (
        <Grid container style={{ padding :  15 }} >
          <Drawer anchor='right' open={showHyperLocalBookingPreview}  >
            <HyperLocalPreview id={group.name} open={showHyperLocalBookingPreview} />
          </Drawer>
        <Grid item xs={12} > 
            {group.createdAt_Formatted && 
                <Typography variant='caption'  className={styles.groupDate} > {group.createdAt_Formatted}</Typography>
            }
        </Grid>


        <Grid item xs={12} style={{ backgroundColor : '#fff' , padding : 10, marginBottom: 20 }}>
        <Grid container>
        <Grid item xs={12} className={styles.groupTitleWrap} >
          
          <Grid container>
              <Grid item xs={2} alignItems="center" justify="center" alignContent="center">
                <Typography style={{ paddingTop : 5 }}> 
                  Group #{group.name}
                </Typography>
              </Grid>
              <Grid  item xs={4}>
                
              </Grid>
              <Grid item xs={6} style={{ display : 'flex'}} justify="flex-end" alignItems="center">
                {group.status == 'processing' && group.hasDeliveryBoyAssigned && <Button onClick={async () => {
                    setGroupOutForDelivery(true);
                    await axios.post(`https://pok-backend-y3hbx.ondigitalocean.app/api/v1/groups/out`,{ data :  { name : group.name  }}).catch((error) => {
                        console.log({error});
                    });
                    onReload();
                  
                }} disabled={markingGroupOutForDelivery} >
                  MARK OUT For Delivery
                </Button>}
              

                {(activeWefastGroupId != group.name && !calculatingWefastTotal )&& <Button  
                style={{ marginLeft :  30}}
                onClick={async () => {
                    setShowHyperLocalBookingPreview(!showHyperLocalBookingPreview);
                    // setCalculatingWefastTotal(true);
                    // let groupedOrderIds = [];
                    // group.orders.map(order => {
                    //   groupedOrderIds.push(order.id);
                    // });
    
                    // let payment_amount = 0 ;
                    // let optimizedRoutes = [];
                    // if(groupedOrderIds.length > 0 ){
                    //   const wefastPriceReq = await axios.post(`https://pok-backend-y3hbx.ondigitalocean.app/api/v1/wefast/calculate`,{ data :  { orderIds : groupedOrderIds  }}).catch((error) => {
                    //       console.log({error});
                    //       this.sendNotification('Payment Link Creation Failed' , 'error' );
                    //   });
                    //   const { data } = wefastPriceReq ;
                    //   payment_amount = data.payment_amount ;
                    //   optimizedRoutes = data.points ;
                      
                    // }
                    
                    // setWefastEstimate(payment_amount);
                    // setActiveWefastGroupId(group.name);
                    // setCalculatingWefastTotal(false);
                }}>
                  Check Wefast
                </Button>}
                {(activeWefastGroupId == group.name && !calculatingWefastTotal ) && <Typography > Wefast Est : ₹{wefastEstimate}</Typography>}
                {calculatingWefastTotal && <Typography  > ... </Typography> }
                <Button onClick={() => onReload()}>
                  
                  <ReplayIcon />
                </Button>

                {group.status != 'completed' && 
                <Button onClick={async () => {
                    const confirmDelete = window.confirm(`Are you sure you want to delete Group #${group.name}`)

                    if(confirmDelete){
                      await axios.post(`https://pok-backend-y3hbx.ondigitalocean.app/api/v1/groups/remove`,{ data :  { name : group.name  }}).catch((error) => {
                        console.log({error});
                      });
                      onReload();
                    }
                  
                }}>
                  <DeleteOutlinedIcon />
                </Button>}
              </Grid>
          </Grid>
          
        </Grid>
        <Grid item xs={12} style={{ backgroundColor : '#fff'}} >
          <Grid container>
            <Grid item xs={1}>
                <Typography variant='overline' >Total ORDS</Typography>
                <Typography variant='h3' >{group.orders.length}</Typography>
            </Grid>
            <Grid item xs={1} >
                <Typography variant='overline' >COD ORDS</Typography>
                <Typography variant='h3' >{group.codOrders}</Typography>
            </Grid>
            <Grid item xs={10}>
              <Grid container justify="space-between">
                <Grid item xs={2} >
                  {group.status != 'completed' && <AddOrderToGroup group={group} deliverBoy={group.deliveryBoy} hasDeliveryBoyAssigned={group.hasDeliveryBoyAssigned} />}
                </Grid>
                <Grid item xs={3}>
                  <AsignDeliveryBoy group={group} onReload={onReload} deliverBoy={group.deliveryBoy} hasDeliveryBoyAssigned={group.hasDeliveryBoyAssigned} />
                </Grid>
              </Grid>
            </Grid>
            
          </Grid>
        </Grid> 
        </Grid>
        </Grid>


        <Grid item xs={12} >
          
          <Accordion elevation={0} >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              
            >
                <Typography variant='overline' > Items Required  </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container >
                {group.itemsRequired.map(itemReq => {
                  return(
                    <Grid item xs={2}  >
                      <Typography variant="overline" style={{  display: 'block' , overflow :'hidden', whiteSpace : 'nowrap', maxWidth : '15ch' , textOverflow : 'ellipsis'}}  >{itemReq.name}</Typography>
                      {itemReq.packets.map(packet => {
                        return(
                          <Grid item xs={12} style={{ paddingLeft : 10 }} >
                            <Typography variant="caption" > {packet.size} x {packet.quantity}</Typography>
                          </Grid>
                        )
                      })}
                    </Grid>
                  );
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
            
            
        </Grid>

        <Grid item xs={12} style={{ marginTop : 30  }}>
            <Grid container >
                {group.orders.map(order => (
                  <Grid item xs={12} style={{ borderLeft : '15px solid ', borderLeftColor : hex2rgb('#' + group.name, 0.3) }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {group.status != 'completed' && <Grid container >
                              <Grid item xs={1}>
                              </Grid>
                              <Grid item xs={11} style={{ textAlign : 'right'}}>
                                {order.data.groupID && <Typography variant="overline" style={{ paddingRight : 10 }} >
                                  GroupId : {order.data.groupID}
                                </Typography>}

                                <a href={"https://pok-backend-y3hbx.ondigitalocean.app/api/v1/orders/label/" +  order.data.id  } style={{ marginRight : 30 }} target='_blank'  download>
                                  <Typography  variant="overline" style={{color : 'red', cursor : 'pointer'}}> Download Label </Typography>
                                </a>

                                {order.location && <a href={`https://maps.google.com/?q=${order.location.lat},${order.location.long}`} style={{ marginRight : 30 }} target='_blank'  download>
                                  <Typography  variant="overline" style={{color : 'red', cursor : 'pointer'}}> View Location </Typography>
                                </a>}
                                

                                <Typography  variant="overline" style={{color : 'red', cursor : 'pointer'}}
                                  onClick={async () => {
                                      const wefastPriceReq = await axios.post(`https://pok-backend-y3hbx.ondigitalocean.app/api/v1/groups/order/remove`,{ data :  { name : group.name , orderId : order.data.id  }}).catch((error) => {
                                          console.log({error});
                                      });
                                      window.location.reload();
                                  }}
                                > REMOVE </Typography>
                              </Grid>
                            </Grid>}
                            
                        </Grid> 
                        <Grid item xs={12}>
                            <OrderListItem orderDetails={order.data} />
                        </Grid>
                    </Grid>
                    
                  </Grid>
                ))}
            </Grid>
        </Grid>
      </Grid>

    )
}