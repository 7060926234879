import React , { useState }from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import red from '@material-ui/core/colors/red';


const useStyles = makeStyles((theme) => ({

    OrderListItem:{
        padding : '10px 15px',
        marginBottom : '10px',
        marginTop : '10px'
    },
    orderItemsContainer : {
        borderTop : '1px solid #f9f9f9'
    },
    orderItemsText : {
        fontSize : 13
    },
    chip : {
        textTransform : 'uppercase',
        padding : '2px 10px',
        borderRadius : 15
    },
    orderStatus : {
        textTransform : 'uppercase',
        whiteSpace : 'nowrap',
    }

}));

export default function OrderListItem( props ) {
  const classes = useStyles();

  const { orderDetails, onAddOrder } = props ;
  const [hasAdded, setHasAdded] = useState(false);

  
  const  { name, id ,customer_note,  paymentMethod, date, address, total , phone ,city , postcode , orderItems , status} = orderDetails ;
  
  let mobileFormatted = phone.replace('+91','').replace(' ', '').replace('+91','') ;

  const customFormatDate = format(new Date(date), 'MMM do hh:mm aaa') ;

  let badgeColor = 'secondary' ;
  if(status == 'processing')
    badgeColor = 'primary'
  else if(status == 'out-for-delivery')
    badgeColor = 'error'


    let badgeColorDays = '#FDBDB0';

    if(orderDetails.days_past){

        if(orderDetails.days_past < 2)
            badgeColorDays = '#FBEFC6';
        
        if(orderDetails.days_past == 2)
            badgeColorDays = '#F7C9A1';

    }
  
  return (
    
        <Paper elevation={0} className={classes.OrderListItem} >
            
            {orderDetails.days_past > 1 && <Grid container spacing={5} item style={{ backgroundColor : badgeColorDays , marginBottom :  15, marginTop :  20 , padding : 5  }}>
                <Typography variant='caption' style={{ fontWeight :  500 }} >  This order is {orderDetails.days_past} days old </Typography>
            </Grid>}
            <Badge color={badgeColor} badgeContent={status} className={classes.orderStatus} ></Badge>
            <Grid container spacing={3} >
                
                <Grid item xs={12}>
                    
                    <Grid container spacing={1} alignItems="center" >
                        <Grid item xs={1}>
                            <Checkbox />
                        </Grid>
                        <Grid item xs={3} alignItems="center" direction="row">
                            <Link to={`/orders/${id}`} >
                            <Typography > #{id} </Typography>
                            <Typography > {name} </Typography>
                            </Link>
                        </Grid>
                        <Grid item xs={2} alignItems="center" direction="row">
                            <Chip label={paymentMethod} className={classes.chip} />
                        </Grid>
                        
                        <Grid item xs={4} alignItems="center" direction="row">
                            <a target='_blank' href={'https://web.whatsapp.com/send?phone=+91' +  mobileFormatted } >
                                <Typography style={{ display : 'flex' , alignItems : 'center'}}> 
                                    <img src='https://img.icons8.com/color/452/whatsapp--v1.png' style={{ width : 20 ,  height : 'auto'}} /> 
                                    {mobileFormatted} 
                                </Typography>
                            </a>
                            <Typography > {customFormatDate} </Typography>
                        </Grid>
                        <Grid item xs={2} alignItems="center" direction="row">
                            {!hasAdded && <Button color='secondary' variant='outlined' onClick={() =>  { onAddOrder(orderDetails) ; setHasAdded(true) }} > Add  </Button>}
                            {hasAdded && <Button color='secondary' variant='outlined' onClick={() =>  { onAddOrder(orderDetails) ; setHasAdded(true) }} > Remove  </Button>}
                        </Grid>
                    </Grid>
                    
                </Grid>

                <Grid item xs={12}>
                        <Typography variant='overline'> {address} </Typography>
                </Grid>

                <Grid item xs={12} className={classes.orderItemsContainer}  >
                    <Grid container spacing={1} alignItems="center" >
                        <Grid item xs={6}>
                        {orderItems.map(orderItem => {

                            const { name , quantity } = orderItem ;
                            
                            return(
                                <Grid container spacing={1} key={name} alignItems="center"  >
                                    <Grid item xs={1} alignItems="center" justify="end" direction="row">
                                        
                                    </Grid>
                                    <Grid item xs={4} alignItems="center" justify="end" direction="row" style={{ backgroundColor : quantity > 1 ? '#FCF4A2' : '#fff' , lineHeight : 2 }}>
                                        <Typography className={classes.orderItemsText} > {name} </Typography>
                                    </Grid>
                                    {quantity > 1 && <Grid item xs={3} alignItems="center" direction="row">
                                        <Typography className={classes.orderItemsText} > x {quantity} </Typography>
                                    </Grid>}
                                </Grid>
                            )  
                        })}
                        </Grid>
                        <Grid item xs={2} alignItems="center" direction="row">
                            <Typography > {city} </Typography>
                            <Typography > {postcode} </Typography>
                        </Grid>
                        <Grid item xs={2} alignItems="center" direction="row">
                            <Typography > ₹{total} </Typography>
                        </Grid>
                    </Grid>
                    
                </Grid>
                {customer_note && <Grid item xs={12}  style={{ 
                    borderTop : '1px solid #f9f9f9',
                    background : '#FFFFE0' 
                }}>
                        <Typography variant='overline' style={{ color : '#333', fontWeight : 600 }}> {customer_note} </Typography>
                </Grid>}
            </Grid> 
        </Paper>
    
  );
}