import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import OrderCount from '../../components/orderCount';
import ItemsRequired from '../../components/itemsRequired';
import CODOrdersSummary from '../../components/codOrdersSummary';
import DateRangeSummary from '../../components/dateRangeSummary';

import { withRouter } from 'react-router'



const useStyles = makeStyles((theme) => ({
  ordersCountRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding : '30px 0px',
    background : '#fff'
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor : '#ffeee8',
    color: '#333',
  }
}));



function OrdersCountRow({ onDogSelected }) {
    const classes = useStyles();
    
    return (
        <div className={classes.ordersCountRow}>

            <OrderCount status="processing"title="Processing" />
            <OrderCount status="completed" title="Completed" />
            <OrderCount status="out-for-delivery" title="Out For Delivery" />
            <OrderCount status="dispatched" title="Dispatched" />
            <OrderCount status="on-hold" title="On Hold" />

        </div>
    );
}



class DashboardComponent extends React.Component {
    

    render(){
        
        const classes = this.props.classes;
        return (
            
            <div className={classes.root}>
            
                
            <Grid container spacing={5} direction="row"
                justify="center"
                alignItems="flex-start"
                style={{
                    padding : '15px'
                }}
            >
                <Grid item xs={12}>
                    <OrdersCountRow/>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="h2" variant="h4" align="center"> Today's Summary </Typography>
                </Grid>
                <Grid item xs={12}>
                    <DateRangeSummary/>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="h2" variant="h4" align="center"> Items Required</Typography>
                </Grid>
                <Grid item xs={4}>
                    <ItemsRequired key="processing" status="processing" title="Processing" />
                </Grid>
                <Grid item xs={4}>
                    <ItemsRequired key="out-for-delivery" status="out-for-delivery" title="Out For Delivery" />
                </Grid>
                <Grid item xs={4}>
                    <ItemsRequired key="on-hold" status="on-hold" title="On Hold" />
                </Grid>
                
                
                <Grid item xs={12}>
                    <Typography component="h2" variant="h4" align="center"> Out For Delivery COD Summary </Typography>
                </Grid>
                <Grid item xs={12}>
                    <CODOrdersSummary/>
                </Grid>
            </Grid>


            </div>
        );
    }
  
}


function Dashboard() {
    const classes = useStyles();
    return (
        <DashboardComponent classes={classes} />
    )
}

export default withRouter(Dashboard);