import React, { useState }from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import OrderCount from '../../components/orderCount';
import ItemsRequired from '../../components/itemsRequired';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import { fade, Theme, createStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withRouter } from 'react-router'
import Drawer from '@material-ui/core/Drawer';
import InputBase from '@material-ui/core/InputBase';
import SearhResults from './../../components/searchResults'
import axios from 'axios';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import NotiicationIcon from '@material-ui/icons/Notifications';
import { ToastContainer } from 'react-toastify';
import NotificationList from '../../components/notificationsList';

import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';


function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

  
const useStyles = makeStyles((theme) => ({
  menuLink : {
      color : '#333' ,
      textDecoration : 'none',
      display : 'block',
      width : '100%'
  },
  ordersCountRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding : '30px 0px',
    background : '#fff'
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  appBar: {
    backgroundColor : '#fff',
    color: '#333',
    boxShadow : '0 4px 10px 0 rgb(20 19 34 / 3%), 0 0 10px 0 rgb(20 19 34 / 2%)'
  }
}));



function PositionedSnackbar() {
    const [state, setState] = useState({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;
  
    const handleClick = (newState: SnackbarOrigin) => () => {
      setState({ open: true, ...newState });
    };
  
    const handleClose = () => {
      setState({ ...state, open: false });
    };
  
    const buttons = (
      <React.Fragment>
        <Button onClick={handleClick({ vertical: 'top', horizontal: 'center' })}>Top-Center</Button>
        <Button onClick={handleClick({ vertical: 'top', horizontal: 'right' })}>Top-Right</Button>
        <Button onClick={handleClick({ vertical: 'bottom', horizontal: 'right' })}>
          Bottom-Right
        </Button>
        <Button onClick={handleClick({ vertical: 'bottom', horizontal: 'center' })}>
          Bottom-Center
        </Button>
        <Button onClick={handleClick({ vertical: 'bottom', horizontal: 'left' })}>Bottom-Left</Button>
        <Button onClick={handleClick({ vertical: 'top', horizontal: 'left' })}>Top-Left</Button>
      </React.Fragment>
    );
  
    return (
      <div>
        {buttons}
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          message="I love snacks"
          key={vertical + horizontal}
        />
      </div>
    );
  }


class HeaderComponent extends React.Component {
    constructor(){
        super();

        this.state = {
            openMenu : false,
            openNotifications : false, 
            searchString : 0,
            showSearchResults : false,
            syncingOrders : false,
            syncingOrdersSnackBar : true,
            syncingOrdersSnackBarText : 'Welcome To POK',
            syncingOrdersSnackBarType : 'info',
            dropOrdersMennu : false ,
            dropGroupsMenu : false, 
            dropLeadsMenu : false ,
            dropCodMenu : false ,
            dropMarketingMenu : false ,
            dropEmailsMenu : false ,
            dropOGroupsMenu : false
        }
    }
    syncOrders = () => {
        this.setState({
            syncingOrders : true ,
        })

        this.setState({
            syncingOrdersSnackBar : true,
            syncingOrdersSnackBarText : `Order Sync Started Please Wait 2 - 3 Minutes `,
            syncingOrdersSnackBarType : 'info'
        });
        axios.get(`https://pok-backend-y3hbx.ondigitalocean.app/sync`)
        .then(res => {
            console.log(res.data);
            this.setState({
                syncingOrders : false 
            })
        })
    }

    syncShipments = () => {
        this.setState({
            syncingOrders : true ,
        })

        this.setState({
            syncingOrdersSnackBar : true,
            syncingOrdersSnackBarText : `Order Sync Started Please Wait 2 - 3 Minutes `,
            syncingOrdersSnackBarType : 'info'
        });
        axios.get(`https://pok-backend-y3hbx.ondigitalocean.app/sync_shipments`)
        .then(res => {
            console.log(res.data);
            this.setState({
                syncingOrders : false 
            })
        })
    }

    toggleMenu = ( newState )  => {
        this.setState({
            openMenu : newState
        });
    }
    handleSnackBarClose = () =>{
        this.setState({
            syncingOrdersSnackBar : false
        });   
    }
    render(){
        const { openMenu , openNotifications , searchString  , dropCodMenu , dropOGroupsMenu ,  dropGroupsMenu , dropMarketingMenu, dropEmailsMenu, dropOrdersMennu,dropLeadsMenu, showSearchResults , syncingOrders ,syncingOrdersSnackBar , syncingOrdersSnackBarText  , syncingOrdersSnackBarType } = this.state ;
        const classes = this.props.classes;
        return (
            
            <div className={classes.root}>

            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                <IconButton edge="start" onClick={ () => this.toggleMenu(true)} className={classes.menuButton} color="inherit" aria-label="menu">
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    <img src="https://i0.wp.com/patilkaki.com/wp-content/uploads/2020/10/pk-logo-min.png" alt="Logo" style={{ maxHeight : 70 }} className="img-responsive" />
                </Typography>

                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        placeholder="Search…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        onChange={(e) => { 
                            let val = e.target.value  ;
                            this.setState({
                                searchString : val,
                                showSearchResults : true
                            });

                        }}
                        onBlur={() => {
                            
                            setTimeout(() => {
                                this.setState({
                                    showSearchResults : false 
                                })    
                            }, 1000);
                            
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                    />

                    {showSearchResults && <SearhResults searchString={searchString} />}
                </div>
                    <Button color="secondary" onClick={() => this.syncOrders()} disabled={syncingOrders}>Sync Orders</Button>

                    <Link to="/orders/new">
                        <Button color="secondary" > CREATE ORDER</Button>
                    </Link>
                    <Link to="/dispatch/create">
                        <Button color="secondary" > CREATE GROUP</Button>
                    </Link>
                    <IconButton color="secondary" onClick={() => this.setState({ openNotifications : !openNotifications , syncingOrdersSnackBar : !syncingOrdersSnackBar})} > 
                        <NotiicationIcon />
                    </IconButton>
                    
                </Toolbar>
            </AppBar>

                <ToastContainer />

            
            <Drawer open={openMenu} onClose={() => this.toggleMenu(false)}>
                <List style={{ minWidth : 250 }}>
                    
                    <ListItem button key={'dashboard'}>
                        <Link to="/" className={classes.menuLink}>
                            <ListItemText primary="Dashboard" />
                        </Link>
                    </ListItem>
                
                
                    <ListItem button key={'orders'} onClick={ () => this.setState({
                        dropOrdersMennu : !dropOrdersMennu
                    })}>
                        <ListItemText primary="Orders" />
                        {dropOrdersMennu ? <ExpandLess /> : <ExpandMore />}

                    </ListItem>
                    <Collapse in={dropOrdersMennu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested}>
                                <Link to="/orders" className={classes.menuLink}>
                                    <ListItemText primary="All" />
                                </Link>
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <Link to="/orders/status/orderByPincode" className={classes.menuLink}>
                                    <ListItemText primary="Processing - Order By Pincode" />
                                </Link>
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <Link to="/orders/status/processing" className={classes.menuLink}>
                                    <ListItemText primary="Processing" />
                                </Link>
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <Link to="/orders/status/express" className={classes.menuLink}>
                                    <ListItemText primary="Processing - Express" />
                                </Link>
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <Link to="/orders/status/out-for-delivery" className={classes.menuLink}>
                                    <ListItemText primary="Out For Delivery" />
                                </Link>
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <Link to="/orders/status/on-hold" className={classes.menuLink}>
                                    <ListItemText primary="On Hold" />
                                </Link>
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <Link to="/orders/status/dispatched" className={classes.menuLink}>
                                    <ListItemText primary="Dispatched" />
                                </Link>
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <Link to="/orders/status/completed" className={classes.menuLink}>
                                    <ListItemText primary="Completed" />
                                </Link>
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <Link to="/orders/status/cod" className={classes.menuLink}>
                                    <ListItemText primary="COD Orders" />
                                </Link>
                            </ListItem>
                        </List>
                    </Collapse>

                    <ListItem button key={'groups'} onClick={ () => this.setState({
                        dropOGroupsMenu : !dropOGroupsMenu
                    })}>

                    <ListItemText primary="Groups" />
                        {dropOGroupsMenu ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={dropOGroupsMenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested}>
                                <Link to="/groups" className={classes.menuLink}>
                                    <ListItemText primary="All" />
                                </Link>
                            </ListItem>

                            <ListItem button className={classes.nested}>
                                <Link to="/groups/status/processing" className={classes.menuLink}>
                                    <ListItemText primary="Processing" />
                                </Link>
                            </ListItem>

                            <ListItem button className={classes.nested}>
                                <Link to="/groups/status/out-for-delivery" className={classes.menuLink}>
                                    <ListItemText primary="Out For Delivery" />
                                </Link>
                            </ListItem>

                            <ListItem button className={classes.nested}>
                                <Link to="/groups/status/completed" className={classes.menuLink}>
                                    <ListItemText primary="Completed" />
                                </Link>
                            </ListItem>

                            <ListItem button className={classes.nested}>
                                <Link to="/groups/status/closed" className={classes.menuLink}>
                                    <ListItemText primary="Closed" />
                                </Link>
                            </ListItem>
                        </List>
                    </Collapse>


                    <ListItem button key={'orderZones'} onClick={ () => this.setState({
                        dropGroupsMenu : !dropGroupsMenu
                    })}>
                        <ListItemText primary="Order Zones" />
                        {dropGroupsMenu ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={dropGroupsMenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested}>
                                <Link to="/dispatch/mum_north_mira_road" className={classes.menuLink}>
                                    <ListItemText primary="Mumbai North - Mira Road" />
                                </Link>
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <Link to="/dispatch/mum_south_grant_road" className={classes.menuLink}>
                                    <ListItemText primary="Mumbai South - Grant Road" />
                                </Link>
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <Link to="/dispatch/bhivandi_thane" className={classes.menuLink}>
                                    <ListItemText primary="Bhivandi Thane" />
                                </Link>
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <Link to="/dispatch/kalyan_dombivali" className={classes.menuLink}>
                                    <ListItemText primary="Kalyan Dombivali" />
                                </Link>
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <Link to="/dispatch/badlapur_navi_mumbai" className={classes.menuLink}>
                                    <ListItemText primary="Badlapur - Navi Mumbai" />
                                </Link>
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <Link to="/dispatch/courier" className={classes.menuLink}>
                                    <ListItemText primary="Courier" />
                                </Link>
                            </ListItem>
                        </List>
                    </Collapse>
                    


                    <ListItem button key={'CodManagement'} onClick={ () => this.setState({
                        dropCodMenu : !dropCodMenu
                    })}>
                        <ListItemText primary="Cod Management" />
                        {dropCodMenu ? <ExpandLess /> : <ExpandMore />}

                    </ListItem>

                    <Collapse in={dropCodMenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested}>
                                <Link to="/cod/" className={classes.menuLink}>
                                    <ListItemText primary="All" />
                                </Link>
                            </ListItem>
                        </List>
                    </Collapse>

                    <ListItem button key={'leads'} onClick={ () => this.setState({
                        dropLeadsMenu : !dropLeadsMenu
                    })}>
                        <ListItemText primary="Leads" />
                        {dropLeadsMenu ? <ExpandLess /> : <ExpandMore />}

                    </ListItem>

                    <Collapse in={dropLeadsMenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested}>
                                <Link to="/leads/" className={classes.menuLink}>
                                    <ListItemText primary="All" />
                                </Link>
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <Link to="/leads/status/new" className={classes.menuLink}>
                                    <ListItemText primary="New" />
                                </Link>
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <Link to="/leads/status/converted" className={classes.menuLink}>
                                    <ListItemText primary="Converted" />
                                </Link>
                            </ListItem>
                        </List>
                    </Collapse>
                    

                    <ListItem button key={'marketing'} onClick={ () => this.setState({
                        dropMarketingMenu : !dropMarketingMenu
                    })}>
                        <ListItemText primary="Marketing" />
                        {dropLeadsMenu ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>

                    <Collapse in={dropMarketingMenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button key={'emails'} onClick={ () => this.setState({
                                dropEmailsMenu : !dropEmailsMenu
                            })}>
                                <ListItemText primary="Emails" />
                                {dropEmailsMenu ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            
                            <Collapse in={dropEmailsMenu} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <Link to="/marketing/emails/create/" className={classes.menuLink}>
                                            <ListItemText primary="Create" />
                                        </Link>
                                    </ListItem>
                                    
                                </List>
                            </Collapse>
                        </List>
                    </Collapse>
                    

                    <ListItem button key={'shipments'}>
                        <Link to="/shipments" className={classes.menuLink}>
                            <ListItemText primary="Shipments" />
                        </Link>
                    </ListItem>

                </List>
            </Drawer>

            <Drawer 
                anchor='right'
                open={openNotifications} 
                onClose={() => this.setState({ openNotifications : !openNotifications })} >
                <Typography style={{ padding : 15 , textAlign : 'left', borderBottom : '1px solid #eee '}}> Notifications </Typography>
                
                <NotificationList /> 
            </Drawer>

            <Snackbar open={syncingOrdersSnackBar} autoHideDuration={3000} onClose={() =>this.handleSnackBarClose()}>
                <Alert onClose={() => this.handleSnackBarClose()} severity={syncingOrdersSnackBarType}>
                    {syncingOrdersSnackBarText}
                </Alert>
            </Snackbar>
            </div>
        );
    }
  
}


function Header() {
    const classes = useStyles();
    return (
        <HeaderComponent classes={classes} />
    )
}

export default withRouter(Header);