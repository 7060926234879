

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router'
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from  '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { gql, useQuery , useLazyQuery} from '@apollo/client';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const wooAPI = axios.create({
    baseURL: "https://patilkaki.com/wp-json/wc/v3/",
    params: {
      apiKey: "n/0afA9aU6E-Dzs3u181Ui22DGJjSfNAbjuSMRD3lq"
    }
});

const useStyles = makeStyles((theme) => ({
    ordersCountRow: {
      display: 'flex',
      justifyContent: 'space-between',
      padding : '30px 0px',
      background : '#fff'
    },
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    appBar: {
      backgroundColor : '#ffeee8',
      color: '#333',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 80,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    stickyOverview : {
        position : 'fixed' ,
        bottom : 0 ,
        right : 0 ,
        width : '50%'
    },
    contactInfoRoot: {
        '& > *': {
            margin: theme.spacing(1),
            maxWidth : '90%'
        },
    },
}));



const GET_SINGLE_ORDER = gql`
query GetOrder($orderId: Int!){
  order( id : $orderId ){
      id
      billing{
        first_name
        last_name
        address_1
        address_2
        city
        state
        country
        phone
        postcode
        email
      }
  }
}
`;


function SearchOrderNoResults( props ) {
    const classes = useStyles();
  
    let { searchString ,  loadDefaultOrderDetails , hideResults } = props ;

    const GET_ORDERS = gql`
      query{
        searchResults( searchString : "${searchString}" ){
            id
            name
        }
      }
    `;



    const { loading, error, data } = useQuery(GET_ORDERS);
    
    if( loading ){
      return (
        <React.Fragment>
            <p>Loading</p>
        </React.Fragment>  
      );
    }
  
    if(error){
        return ( 
          <span>{error}</span>
        )
    }
  
    const { searchResults } = data ;
    
  
    
    return (
        <List style={{ minWidth : 250 }} className={classes.searchResults} >
            
            {searchResults.map((item) => (
                <ListItem button key={item.id} onClick={() => { loadDefaultOrderDetails({ variables: { orderId: item.id } }) ; hideResults() }}>
                    <ListItemText primary={`#${item.id}`} secondary={item.name} />
                </ListItem>
            ))}        
        
        </List>
    );
  }

function AutofillPreviousOrderInput ( data ){
    const { loadDefaultOrderDetails, setDefault } = data ;
    const [searchString, setSearchString ] = useState('');

    const hideResults = () => {
        setSearchString('');
    }
    return (
        <Paper>
            <TextField size={'small'} fullWidth value={searchString} onChange={(e) => setSearchString(e.target.value)} label="Search Order" variant="outlined" />
            <SearchOrderNoResults hideResults={hideResults} searchString={searchString} loadDefaultOrderDetails={loadDefaultOrderDetails} />
        </Paper>
    )
}

function AddProduct( data ){

    const classes = useStyles();

    const { product, onProductAdd } = data ;

    let defaultVariation = false ;
    if(product)
        defaultVariation = product.variations[ Object.keys(product.variations)[0] ].id;

    const [activeVariation, setActiveVariation] = useState(defaultVariation);



    return (
        <Paper style={{ padding : '2px 15px' }}>
            <Grid container   alignItems="center" >
                <Grid item xs={7}>
                    <Typography variant="button">{product.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <FormControl  className={classes.formControl}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={activeVariation}
                            onChange={(event) => setActiveVariation(event.target.value)}
                            
                        >
                            {product.variations.map(variation => {
                                let option = "";
                                variation.attributes.map(attr => {
                                    option = attr.option ;
                                });

                                return(
                                    <MenuItem value={variation.id}>{option}</MenuItem>
                                )
                            })}
                            
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <Button variant="link" color="secondary" style={{ color : '#ff6a32' }} onClick={() => onProductAdd(activeVariation, product.id)}>
                        Add
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    )
}

function ProductsList( data ) {
    const { products , onProductAdd } = data;

    return(
        <Grid container>
            <Grid item xs={24}>         
                {products.map(item => { 
                        return (
                            <AddProduct product={item} onProductAdd={onProductAdd} />
                        );
                    }
                )}  
            </Grid>
        </Grid>
    )
}

function  AddContactInfo( props ){

    const classes = useStyles();

    const { contactInfo , onContactInfoAdd } = props;
    const [ loadDefaultOrderDetails, { loading, error, data } ] = useLazyQuery(GET_SINGLE_ORDER);

    
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [email, setEmail] = useState('');
    const [address_1, setAddress1] = useState('');
    const [address_2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [pincode, setPincode] = useState('');
    const [state, setState] = useState('');
    const [notes, setNotes] = useState('');

    const [couponCode , setCouponCode] = useState('');
    const [deliveryFee , setDeliveryFee] = useState('');

    if(loading){
        return(
            <p>Loading </p>
        )
    }
    
    console.log({data});
    
    const setDefault = () => {
        const {order } = data ;
        const { billing } = order ;

        setFirstName(billing.first_name);
        setLastName(billing.last_name);
        setMobileNo(billing.phone);
        setEmail(billing.email);
        setAddress1(billing.address_1)
        setAddress2(billing.address_2)
        setCity(billing.city)
        setState(billing.state)
        setPincode(billing.postcode)

        console.log({billing});
    }
    return (
        <>

        <AutofillPreviousOrderInput setDefault={setDefault} loadDefaultOrderDetails={loadDefaultOrderDetails} />
        {data && <Button onClick={() => setDefault()} fullWidth>  SET INFO - {data.order.billing.first_name} {data.order.billing.last_name} ( #{data.order.id} ) </Button>}
        <form className={classes.contactInfoRoot}>              
            <TextField id="first-name" label="First Name" variant="outlined" value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
            <TextField id="last-name" label="Last Name" variant="outlined" value={lastName} onChange={(e) => setLastName(e.target.value)}/>

            <TextField id="mobile" label="Mobile" fullWidth variant="outlined" value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} />

            <TextField id="email" label="Email" fullWidth variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} />
            <TextField id="adddress_1" label="Address 1" fullWidth variant="outlined" value={address_1} onChange={(e) => setAddress1(e.target.value)} />
            <TextField id="address_2" label="Address 2" fullWidth variant="outlined" value={address_2} onChange={(e) => setAddress2(e.target.value)} />
            <TextField id="city" label="City" fullWidth variant="outlined" value={city} onChange={(e) => setCity(e.target.value)} />
            <TextField id="pincode" label="Pincode" fullWidth variant="outlined" value={pincode} onChange={(e) => setPincode(e.target.value)} />
            <TextField id="state" label="State" fullWidth variant="outlined" value={state} onChange={(e) => setState(e.target.value)} />

            <TextField id="couponCode" label="Coupon Code" fullWidth variant="outlined" value={couponCode} onChange={(e) => setCouponCode(e.target.value)} />
            <TextField id="deliveryFee" label="Delivery Fee" fullWidth variant="outlined" value={deliveryFee} onChange={(e) => setDeliveryFee(e.target.value)} />

            <TextField id="notes" label="Customer Notes" fullWidth rows={3} variant="outlined" value={notes} onChange={(e) => setNotes(e.target.value)} />

            
            <Button fullWidth variant="contained" color="primary" onClick={() => onContactInfoAdd({ contact : { firstName , lastName, mobileNo , email } , billing : { address_1, address_2, city , postcode : pincode , state } , extra : { couponCode, deliveryFee , notes }  })}> CREATE ORDER </Button>
        </form>
        </>

    )
}


function OrderReview( data ){
    const { order } = data ;

    return (
        <Grid container>
            <Grid item>
                <TableContainer component={Paper}>
                    <TableBody>

                        <TableRow key={'orderTitle'}>
                            <TableCell align="left" component="th" scope="row" > <strong> Order Items </strong></TableCell>
                            <TableCell align="right"> </TableCell>
                        </TableRow>
                        {order.lineItems && order.lineItems.map((lineItem) => (
                            <TableRow key={lineItem.variationID}>
                                <TableCell align="left">{lineItem.name}</TableCell>
                                <TableCell align="right">₹{lineItem.price}</TableCell>
                            </TableRow> 
                        ))}

                        <TableRow key={'orderTotal'}>
                            <TableCell align="left" component="th" scope="row" > Order Total</TableCell>
                            <TableCell align="right" component="th" > <b>₹{order.total}</b></TableCell>
                        </TableRow> 
                    </TableBody>

                </TableContainer>
            </Grid>
        </Grid>
    )
}

class CreateOrderComponent extends React.Component {
    
    constructor(props){
        super(props);

        this.state = {
            loading : true , 
            products : [],
            order: { total : 0 },
            step : 0,
            newOrderLoading : false, 
            showNewOrderPopup : false,
            newOrder : {}
        }

    }

    handleNewOrderClickOpen = () => {
        this.setState({
            showNewOrderPopup : true 
        })
    };
    
    handleNewOrderClose = () => {
        this.setState({
            showNewOrderPopup : false 
        })
    };


    onContactInfoAdd = async ( contactData ) =>{
        var { order } = this.state ;
        
        order = {
            ...order,
            ...contactData
        };

        this.setState({
            newOrderLoading : true 
        })
        this.setState({
            showNewOrderPopup : true 
        })
        const newOrder = await axios.post(`https://pok-backend-y3hbx.ondigitalocean.app/api/v1/orders/create`,{ data :  order} );

        const { data } = newOrder ;

        console.log({newOrder});
        this.setState({
            newOrder : data,
        })
        this.setState({
            newOrderLoading : false
        })
        this.setState({
            order : order
        })
    }
    onProductAdd =  ( variationID, productID ) => {
        const { products , order} = this.state ;
        
        const product = products[productID] ;
        const variant = product.variations[variationID];

        
        let option = "";
        variant.attributes.map(attr => {
            option += attr.option ;
        });

        let lineItems = []
        let orderTotal = 0 ;

        if(order.lineItems)
            lineItems = order.lineItems;

        if(order.total)
            orderTotal = order.total;

    
        orderTotal += parseInt(variant.price);

        var lineItem = {
            price : variant.price,
            name : `${product.name} - ${option}`,
            productID : product.id ,
            variationID : variant.id,
            type: 'product'
        }

        lineItems.push(lineItem);


        order.lineItems = lineItems ;
        order.total = orderTotal ;


        this.setState({
            order: order
        })

    }

    async fetchProducts () {
        var productsData = [];
        var products = await wooAPI.get('products/?per_page=100&status=publish');
        products = products.data ;
        var i = 0 ;
        products.forEach(async product => {
            
            var variations = await wooAPI.get(`products/${product.id}/variations`);
            variations = variations.data ;

            var finalVariations = [] ; 
            variations.forEach(variation => {

                if(variation)
                    finalVariations[variation.id] = variation ;

            });
            
            var productData = {
                id : product.id,
                name : product.name,
                variations : finalVariations
            };

            if(product.stock_status == 'instock' && variations.length > 1 ){
                productsData[product.id] = productData;
                this.setState({
                    products : productsData
                });
            }

            i++ ;
        });

        
        this.setState({
            loading : false
        })
    }

    componentWillMount(){
        this.fetchProducts();
    }

    nextStep = () => {
        this.setState({
            step : (this.state.step + 1)
        })
    }

    backStep = () => {
        this.setState({
            step : (this.state.step - 1)
        })
    }
    
    render(){
        const { products , loading , order , step , showNewOrderPopup, newOrder, newOrderLoading } = this.state ;

        var editLink = '#' ;
        let paymentLink = '#';

        if(newOrder.orderId)
            editLink = `https://patilkaki.com/wp-admin/post.php?post=${newOrder.orderId}&action=edit`;

        if(newOrder.orderData)
            paymentLink = `https://patilkaki.com/checkout/order-pay/${newOrder.orderId}/?pay_for_order=true&key=${newOrder.orderData.order_key}`;


        const classes = this.props.classes;

        return (
            <>
            <Dialog
                open={showNewOrderPopup}
                onClose={this.handleNewOrderClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"New Order Created"}</DialogTitle>
                <DialogContent>
                    {newOrderLoading && <p> Loading </p>}
                    {!newOrderLoading && (<a href={editLink} target='_blank' > Edit #{newOrder.orderId} On WOO </a>)}
                    <br />
                    <Typography variant='caption'> Payment Link - </Typography>
                    {!newOrderLoading && <Typography  variant='caption' style={{cursor : 'pointer'}}  onClick={() => {navigator.clipboard.writeText(paymentLink)}}  > {paymentLink}</Typography>}
                </DialogContent>
                <DialogActions>
                
                <Button onClick={this.handleNewOrderClose} color="primary">
                    Close
                </Button>
                </DialogActions>
            </Dialog>

            <Grid container spacing={3}>
                
                <Grid item style={{ width : '40%'}}>
                    <Paper justifyContent="center" style={{padding : 15 }}  >

                        <Paper style={{ display : 'flex', justifyContent : 'space-between'}} elevation={0} >
                            {step > 0 && <Button variant="link" color="seocondary" align="center"  onClick={() => this.backStep()} > Back </Button>}
    
                            {step == 0 && <Typography variant="h6" justifyContent="center" align="center"> Add Products </Typography>}
                            {step == 1 && <Typography variant="h6" justifyContent="center" align="center"> Add Contact & Delivery </Typography>}
                            {step == 2 && <Typography variant="h6" justifyContent="center" align="center"> Discount & Shipping </Typography>}


                            {step < 3 && <Button variant="contained" color="primary" align="center" onClick={() => this.nextStep()} > Next </Button>}
                        </Paper>
                        
                    
                        <div style={{maxHeight: 500, overflow: 'scroll'}}  >
                        {!loading && 
                            <>
                                {step == 1 && <AddContactInfo onContactInfoAdd={this.onContactInfoAdd} />}
                                {step == 0 && <ProductsList products={products} onProductAdd={this.onProductAdd} />}
                            </>
                        }
                        </div>
                    </Paper>
                </Grid>
                <Grid item style={{ width : '40%'}}>
                    <OrderReview order={order} className={classes.stickyOverview}/>
                </Grid>
                    
            </Grid>
            
            </>
        );

    }

}


function CreateOrder() {
    const classes = useStyles();
    return (
        <CreateOrderComponent classes={classes} />
    )
}

export default withRouter(CreateOrder);