

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router'
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import { gql, useQuery } from '@apollo/client';
import OrderShipmentItem from '../../components/orderShipmentItem' ;
import Skeleton from '@material-ui/lab/Skeleton';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';


const wooAPI = axios.create({
    baseURL: "https://patilkaki.com/wp-json/wc/v3/",
    params: {
      apiKey: "n/0afA9aU6E-Dzs3u181Ui22DGJjSfNAbjuSMRD3lq"
    }
});

const useStyles = makeStyles((theme) => ({
    ordersCountRow: {
      display: 'flex',
      justifyContent: 'space-between',
      padding : '30px 0px',
      background : '#fff'
    },
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    appBar: {
      backgroundColor : '#ffeee8',
      color: '#333',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 80,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    stickyOverview : {
        position : 'fixed' ,
        bottom : 0 ,
        right : 0 ,
        width : '50%'
    },
    contactInfoRoot: {
        '& > *': {
            margin: theme.spacing(1),
            maxWidth : '90%'
        },
    },
}));


function ListGroupOrders( props ) {
    const classes = useStyles();
    const [refreshing, setRefreshing ] = useState(false);
  
    const {  status , page , onAddOrder , groupedOrderIds , onRemoveOrderFromGroup , zone } = props ;
    let zone_names = ['Mumbai_North','Mumbai_South', 'Mumbai_NorthEast_Thane','Mumbai_East_Navi','Courier'];

    const GET_ORDERS = gql`
      query{
        ordersList ( status :"${status}" , page : ${page} , zone : "${zone_names[zone]}" ){
          id 
          name
          status
          paymentMethod
          total
          phone
          city
          date
          address
          postcode
          orderItems {
            name 
            quantity 
          }
          customer_note
          days_past
        }
      }
    `;
    const { loading, error, data , refetch} = useQuery(GET_ORDERS);

    const onReload = async () => {
      setRefreshing(true);
      await refetch();
      setRefreshing(false);
    }
    
    if( loading ||  refreshing  ){
      return(
        <Skeleton variant="rect" width={'100%'}  height={118} />
      )
    }
  
    if(error){
        return ( 
          <span>{error.message}</span>
        )
    }
  
    const { ordersList } = data ;
    
  
    return (
      <div>
        <Grid container>
          <Grid item xs={12}> 
          {!ordersList.length && 
            <h4>
                No New Orders In {status}
            </h4>
          }
          {ordersList.length && 
            <Paper elevation={0} style={{ padding : 10  , display : 'flex' , justifyContent : 'space-between' , alignItems : 'center'}} >
              <Typography variant='overline'> Total Orders : {ordersList.length} </Typography>

              <ReplayOutlinedIcon onClick={() => onReload()}  style={{ cursor : 'pointer'}} />
            </Paper>
          }
          {ordersList.map((order) => {
            if(groupedOrderIds.includes(order.id)){
              return (
                <Paper style={{ background : '#e1eded' , padding : 15 , marginTop : 15 }}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant='overline' >#{order.id} </Typography>
                      <Typography variant='overline' > {order.name}</Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign : 'right'}}>
                      <Typography variant='overline' style={{color : 'red'}} onClick={() => onRemoveOrderFromGroup(order.id)}> Remove </Typography>
                    </Grid>
                  </Grid>
                  
                </Paper>
              )
            } 

            return (
              <OrderShipmentItem orderDetails={order} onAddOrder={onAddOrder} />
            )
          })}
          </Grid>
        </Grid>
        </div>
    );
  }


function GroupOrderOverview ( props  ){
    const {  orders , onRemoveOrderFromGroup , loading, onCreateOrderGroup,  wefastEstimate , calculatingWefast } = props ;
    const [pickUpTime , setPickupTime] =  useState('04:05 PM');
    const [startEndLocation , setStartEndLocation] =  useState('');
    return (
        <Paper style={{ padding : 15 }}>
            <Grid container>
                <Grid item xs={4} style={{ textAlign : 'center'}}>
                    <Typography  variant="overline" >Total Orders</Typography>
                    <Typography  variant="h5"  >{orders.length}</Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign : 'center'}}>
                    <Typography  variant="overline" >Est WeFast</Typography>
                    {!calculatingWefast && <Typography  variant="h5"  > ₹{wefastEstimate} </Typography>}
                    {calculatingWefast && <Typography  variant="h5"  > ... </Typography>}
                </Grid>
                <Grid item xs={4} style={{ textAlign : 'center'}}>
                    <Typography  variant="overline" >Avg Cost</Typography>
                    {!calculatingWefast && <Typography  variant="h5"  > ₹{( wefastEstimate / orders.length).toFixed(2)} </Typography>}
                    {calculatingWefast && <Typography  variant="h5"  > ... </Typography>}
                </Grid> 
            </Grid>
            <Grid container style={{ padding : 15 }} >
                {orders.map(order => {


                  return(
                    <Grid item xs={12} style={{ paddingBottom : 15 , marginBottom : 15 }}>
                        <Grid container>
                                <Grid item xs={8}>
                                    <Typography variant="overline" >#{order.id}</Typography>
                                    <Typography variant="overline"> {order.name}</Typography>
                                </Grid>
                                <Grid item xs={4} style={{ textAlign : 'right'}} >
                                    <Typography variant="overline" style={{color : 'red'}} onClick={() => onRemoveOrderFromGroup(order.id)} > Remove </Typography>
                                </Grid>
                        </Grid>
                    </Grid>
                  )  
                })}
            </Grid>
            
            {orders.length  >  0 &&  <Grid container >
                <Grid item xs={12}>
                  <Grid container style={{ display : 'flex' , alignItems: 'center'}} justify="space-between" spacing={20} >
                    <Grid item xs={5}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                          margin="normal"
                          id="time-picker"
                          label="PICKUP TIME"
                          value={pickUpTime}
                          
                          onChange={(date,val) => { 
                            setPickupTime(date) 
                            console.log({date})
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={5}>
                      <TextField id="outlined-basic" label="Start-End Point" 
                        value={startEndLocation}
                        onChange={(e) => setStartEndLocation(e.target.value)}
                      variant='standard' />
                    </Grid>
                  </Grid>
                  

                </Grid>
                <Grid item xs={12}>
                  { (pickUpTime != '' && startEndLocation != '' ) && 
                    <Button variant="outlined" color={'secondary'} 
                      disabled={loading}
                      onClick={ () => onCreateOrderGroup(pickUpTime , startEndLocation ) } 
                      fullWidth block> 
                      Create Group 
                    </Button>}
                </Grid>
            </Grid>}
        </Paper>
    );
}


class CreateShipmentGroupComponent extends React.Component {
    
    constructor(props){
        super(props);

        this.state = {
            loading : true , 
            products : [],
            order: { total : 0 },
            step : 0,
            currentTab : 0 , 
            newOrderLoading : false, 
            showNewOrderPopup : false,
            newOrder : {},
            orders : [],
            groupedOrderIds : [],
            wefastEstimate : 0 ,
            calculatingWefast : false , 
            optimizedRoutes : [],
            creatingGroupLoading : false ,
        }

    }


    calculateWeFastEstimate = async () => {
        let { groupedOrderIds  } = this.state ;
        
        this.setState({
          calculatingWefast : true
        });
        let payment_amount = 0 ;
        let optimizedRoutes = [];
        if(groupedOrderIds.length > 0 ){
          const wefastPriceReq = await axios.post(`https://pok-backend-y3hbx.ondigitalocean.app/api/v1/wefast/calculate`,{ data :  { orderIds : groupedOrderIds  }}).catch((error) => {
              console.log({error});
              this.sendNotification('Payment Link Creation Failed' , 'error' );
          });
          const { data } = wefastPriceReq ;
          payment_amount = data.payment_amount ;
          optimizedRoutes = data.points ;
          console.log(data);
        }
        
        this.setState({
            wefastEstimate : payment_amount,
            optimizedRoutes : optimizedRoutes ,
            calculatingWefast : false 
        })

    }
 

    removeOrderFromGroup = async ( orderId ) => {
      let { orders  , groupedOrderIds } = this.state ;
      let revisedOrders = [];
      let revisedGroupOrderIds = [];
      let i = 0 ;

      while (i <  orders.length){
        if(orders[i].id != orderId ){
          revisedOrders.push(orders[i]);
          revisedGroupOrderIds.push(orders[i].id);
        }
        i++;
      }

      await this.setState({
        orders : revisedOrders,
        groupedOrderIds :  revisedGroupOrderIds 
      });

      this.calculateWeFastEstimate();

    }
    addOrderToGroup = ( order ) => {
        let { orders  , groupedOrderIds } = this.state ;

        orders.push(order) ;
        groupedOrderIds.push(order.id) ;

        this.setState({
            orders : orders,
            groupedOrderIds :  groupedOrderIds 
        });

        this.calculateWeFastEstimate();

    }

    createOrderGroup = async ( pickUpTime , startEndLoc) => {
      console.log({ pickUpTime, startEndLoc});
      let { groupedOrderIds } = this.state ;
      this.setState({
        creatingGroupLoading : true 
      })

      const groupresponse = await axios.post(`https://pok-backend-y3hbx.ondigitalocean.app/api/v1/groups/create`,{ data :  { pickUpTime : pickUpTime , startEndLoc : startEndLoc ,  orderIds : groupedOrderIds }}).catch((error) => {
        console.log({error});
      });
      
      if(groupresponse){
        
        setTimeout(() => {
          window.location.href = '/groups/status/processing';
        }, 500);
        
      }

      this.setState({
        creatingGroupLoading : false 
      })
    

    }
 
    render(){


        const classes = this.props.classes;
        let { orders , wefastEstimate , currentTab, groupedOrderIds , optimizedRoutes, calculatingWefast , creatingGroupLoading} = this.state ; 

        return (
            
            <Grid container spacing={3}>
                <Grid item xs={8} >
                <Paper className={classes.root}>
                  <Tabs
                    value={currentTab}
                    onChange={(event, tab) => this.setState({ currentTab : tab })}
                    indicatorColor="secondary"
                    textColor="secondary"
                  >
                    <Tab label="North" />
                    <Tab label="South" />
                    <Tab label="NEast-Thane" />
                    <Tab label="East-Navi" />
                    <Tab label="Courier" />
                  </Tabs>
                </Paper>  
                  
                    <ListGroupOrders status='ordersToGroup' zone={currentTab} page={0} onAddOrder={this.addOrderToGroup} groupedOrderIds={groupedOrderIds} onRemoveOrderFromGroup={this.removeOrderFromGroup}  />
                </Grid>    
                <Grid item xs={4} >
                    <h3> Grouped Orders </h3>    
                    <GroupOrderOverview 
                      orders={orders} 
                      wefastEstimate={wefastEstimate} 
                      optimizedRoutes={optimizedRoutes} 
                      calculatingWefast={calculatingWefast} 
                      onCreateOrderGroup={this.createOrderGroup} 
                      onRemoveOrderFromGroup={this.removeOrderFromGroup} 
                      loading={creatingGroupLoading}
                    />
                </Grid>    
            </Grid>
            
        );

    }

}


function CreateShipmentGroup() {
    const classes = useStyles();
    return (
        <CreateShipmentGroupComponent classes={classes} />
    )
}

export default withRouter(CreateShipmentGroup);