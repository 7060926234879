import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Badge from '@material-ui/core/Badge'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Switch from '@material-ui/core/Switch';
import axios from 'axios';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import SetLocation from '../orders/setLocation';

const useStyles = makeStyles((theme) => ({

    OrderListItem:{
        padding : '10px 15px',
        marginBottom : '10px',
        marginTop : '10px'
    },
    orderItemsContainer : {
        borderTop : '1px solid #f9f9f9'
    },
    orderItemsText : {
        fontSize : 15,
        display : 'flex',
        alignItems : 'center'
    },
    chip : {
        textTransform : 'uppercase',
        padding : '2px 10px',
        borderRadius : 15
    },
    orderStatus : {
        textTransform : 'uppercase',
        whiteSpace : 'nowrap',
    }

}));


function hex2rgb(hex, opacity) {
    var h=hex.replace('#', '');
    h =  h.match(new RegExp('(.{'+h.length/3+'})', 'g'));

    for(var i=0; i<h.length; i++)
        h[i] = parseInt(h[i].length==1? h[i]+h[i]:h[i], 16);

    if (typeof opacity != 'undefined')  h.push(opacity);

    return 'rgba('+h.join(',')+')';
}

export default function OrderListItem( props ) {
  const classes = useStyles();

  const { orderDetails } = props ;
  
  const  { name, id , customer_note,  paymentMethod, date, address,  total , groupID , phone ,city , postcode , orderItems , status} = orderDetails ;
  
  let mobileFormatted = phone.replace('+91','').replace(' ', '').replace('+91','') ;

  const customFormatDate = format(new Date(date), 'MMM do hh:mm aaa') ;

  let badgeColor = 'secondary' ;
  if(status == 'processing')
    badgeColor = 'primary'
  else if(status == 'out-for-delivery')
    badgeColor = 'error'

  
  return (
    
        <Paper elevation={0} className={classes.OrderListItem} >
            <Badge color={badgeColor} badgeContent={status} className={classes.orderStatus} ></Badge>

            <Grid container spacing={3} >

                <Grid item xs={12}>
                    
                    <Grid container spacing={1} alignItems="center" >
                        <Grid item xs={1}>
                            <Checkbox />
                        </Grid>
                        <Grid item xs={2} alignItems="center" direction="row">
                            <Link to={`/orders/${id}`} >
                                <Typography variant='overline' color='secondary' > View Order </Typography>
                            </Link>
                            <Typography style={{ cursor : 'pointer' }}  onClick={() => {navigator.clipboard.writeText(id)}} > #{id} </Typography>
                            <Typography style={{ cursor : 'pointer' }} onClick={() => {navigator.clipboard.writeText(name)}} > {name} </Typography>
                        </Grid>
                        <Grid item xs={3} alignItems="center" direction="row">
                            <Chip label={paymentMethod} className={classes.chip} />
                        </Grid>
                        <Grid item xs={2} alignItems="center" direction="row">
                            <Typography > {city} </Typography>
                            <Typography style={{ cursor : 'pointer'}} onClick={async () => {
                                const pinCodeData = await axios.get(`https://api.postalpincode.in/pincode/${postcode}`).catch((error) => {
                                    console.log({error});
                                    alert('failed to grap data');
                                });
                                
                                let { data } = pinCodeData ;
                                
                                data.forEach(singleData => {
                                    singleData.PostOffice.forEach(postoffice => {
                                        // console.log(postoffice);
                                        alert(postoffice.Name);
                                    }) 
                                });

                            
                            }} > {postcode} </Typography>
                        </Grid>
                        <Grid item xs={2} alignItems="center" direction="row">
                            <Typography style={{ display : 'flex' , alignItems : 'center',  cursor : 'pointer'  }}  onClick={() => {navigator.clipboard.writeText(mobileFormatted)}}  > 
                                {mobileFormatted} 
                            </Typography>
                        
                            <Typography > {customFormatDate} </Typography>
                        </Grid>
                        <Grid item xs={2} alignItems="center" direction="row">
                            <Typography > ₹{total} </Typography>
                            {paymentMethod == 'cod' && <Switch
                                defaultChecked
                                color="default"
                                inputProps={{ 'aria-label': 'checkbox with default color' }}
                            />}
                        </Grid>
                    </Grid>
                    
                </Grid>


                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center" >
                        <Grid item xs={1}>
                        
                        </Grid>
                        <Grid item xs={11}>
                            <SetLocation orderId={id} />
                        </Grid>
                    </Grid>
                    
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center" >
                        <Grid item xs={1}>
                        
                        </Grid>
                        <Grid item xs={11}>
                            <Typography style={{ cursor : 'pointer' }} onClick={() => {navigator.clipboard.writeText(address)}} >{address}</Typography>
                        </Grid>
                    </Grid>
                    
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center" >
                        <Grid item xs={1}  >
                        
                        </Grid>
                        <Grid item xs={11}>
                            
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.orderItemsContainer}  >
                    {orderItems.map(orderItem => {

                        const { name , quantity } = orderItem ;
                        
                        return(
                            <Grid container spacing={1} key={name} alignItems="center"  >
                                <Grid item xs={1} alignItems="center" justify="end" direction="row">
                                    
                                </Grid>
                                <Grid item xs={4} alignItems="center" justify="end" direction="row" style={{ backgroundColor : quantity > 1 ? '#FCF4A2' : '#fff' , lineHeight : 2 }}>
                                    
                                    <Typography className={classes.orderItemsText} > {name.includes('Chakli') && <DonutLargeIcon style={{ color : 'red'}} />} {name} </Typography>
                                </Grid>
                                {quantity > 1 && <Grid item xs={3} alignItems="center" direction="row">
                                    <Typography className={classes.orderItemsText} > x {quantity} </Typography>
                                </Grid>}
                            </Grid>
                        )  
                    })}
                    
                </Grid>
                {customer_note && <Grid item xs={12}  style={{ 
                    borderTop : '1px solid #f9f9f9',
                    background : '#FFFFE0' 
                }}>
                        <Typography variant='overline' style={{ color : '#333', fontWeight : 600 }}> {customer_note} </Typography>
                </Grid>}
            </Grid> 
        </Paper>
    
  );
}