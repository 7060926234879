import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { gql, useQuery } from '@apollo/client';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  orderCount:{
      padding : '15px'
  },
  divider : {
    marginBottom : '10px'
  },
  shipmentDetails : {

  }

}));

export default function OrderShipments( props ) {
  const classes = useStyles();

  const {  id  } = props ;

  const GET_ORDERS = gql`
    query {
      paymentLink( woo_id : ${id} ){
          id
          amount
          status
          link
          woo_id
        }
    }  
  `;
  const { loading, error, data } = useQuery(GET_ORDERS);
  
  if( loading ){
    return(
      <Skeleton variant="rect" width={210} height={118} />
    )
  }

  if(error){
      return ( 
      <span>{error}</span>
      )
  }

  const { paymentLink } = data ;
  
  return (
    <div className={classes.root}>
        <Paper elevation={1} className={classes.orderCount} >
            
            <Typography variant="button" display="block" gutterBottom >
                Custom Payments For #{id}
            </Typography>
            <Divider className={classes.divider} />

            {!paymentLink || !paymentLink.length && (
                <>
                    <Typography> No Payment Links Found </Typography>
                </>
            )}

            {paymentLink && 
              paymentLink.map(( singlePaymentLink) => {

                return (
                  <Grid container style={{ paddingBottom : 10 , paddingTop : 10}}>
                    <Grid item xs={12} >
                      <Grid container  justify="space-between">
                          <Grid item xs={3} >  
                            <Typography variant="overline" > ₹ {singlePaymentLink.amount}  </Typography>
                          </Grid>
                          <Grid item xs={3}>
                          <Chip variant="outlined" size="small" style={{ borderRadius : 0 , textTransform : 'uppercase'}} color={singlePaymentLink.status == 'captured' ? 'secondary' : 'primary' } label= {singlePaymentLink.status}  />
                          </Grid>
                      </Grid>

                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption" display="block" > <a href={singlePaymentLink.link} target="_blank"> {singlePaymentLink.link} </a> </Typography>
                    </Grid>                    
                    
                  </Grid>
                )

              })

                
            }
            

        </Paper>
    </div>
  );
}