import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import { gql, useQuery } from '@apollo/client';
import OrderListItem from '../orderListItem' ;

const useStyles = makeStyles((theme) => ({

  itemsRequired:{
      padding : '20px 0px'
  },
  pagination : {
    float : 'right'
  }
}));

export default function ListOrders( props ) {
  const classes = useStyles();

  const {  status , page } = props ;
  const GET_ORDERS = gql`
    query{
      ordersList ( status :"${status}" , page : ${page} ){
        id 
        name
        status
        paymentMethod
        address
        total
        phone
        city
        date
        postcode
        groupID
        orderItems {
          name 
          quantity 
        }
        customer_note
      }
    }
  `;
  const { loading, error, data } = useQuery(GET_ORDERS);
  
  if( loading ){
    return(
      <Skeleton variant="rect" width={210} height={118} />
    )
  }

  if(error){
    console.log({error});
      return ( 
        <span>{error.message}</span>
      )
  }

  const { ordersList } = data ;
  

  
  return (
    <div>
      <Grid container>
        <Grid item xs={12}> 
        {ordersList.map((order) => (
          <OrderListItem orderDetails={order} />
        ))}
        </Grid>
      </Grid>
      </div>
  );
}