import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import { gql, useQuery } from '@apollo/client';
import Typography from '@material-ui/core/Typography';

import LinearProgress from '@material-ui/core/LinearProgress';
import GroupItem  from '../groups/groupItem';

const useStyles = makeStyles((theme) => ({

  itemsRequired:{
      padding : '20px 0px'
  },
  pagination : {
    float : 'right'
  }
}));


export default function ListGroups( props ) {
  const classes = useStyles();
  const [ refreshing , setRefreshing ] = useState(false);
  const {  status , page } = props ;
  console.log({status});
  const GET_ORDERS = gql`
    query{
      groups ( status : "${status}" , page : ${page} ) {
        name
        status
        hasDeliveryBoyAssigned
        codOrders
        createdAt
        createdAt_Formatted
        itemsRequired {
          name
          packets {
            size
            quantity
          }
        }
        deliveryBoy {
          name 
          mobile
          amountPaid
        }
        
        orders{
          id
          location {
            lat
            long
          }
          data {
            id 
            name
            status
            paymentMethod
            total
            phone
            city
            date
            address
            postcode
            groupID
            orderItems {
              name 
              quantity 
            }
            customer_note
            
          }
        }
      }
    }
  `;
  const { loading, error, data , refetch } = useQuery(GET_ORDERS,  {
    fetchPolicy: "no-cache"
  });
  

  const onReload = async () =>{
    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  }


  if( loading || refreshing ){
    return(
      <LinearProgress color='secondary' style={{  marginTop : 30 , marginBottom : 100 }}/>
    )
  }

  

  
  if(error){
      return ( 
        <span> Current Page {page} :: {error.message}</span>
      )
  }

  const { groups } = data ;
  
  

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>

        </Grid>

        <Grid item xs={12}> 
        {groups.length < 1 && 
          <Typography variant='h6' align='center' >
             No Group's To Show Here
          </Typography>
        }
        {groups.map(group => <GroupItem group={group}  onReload={onReload} />)}
        </Grid>
      </Grid>
      </div>
  );
}