import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import OrderCount from '../../components/orderCount';
import ListDispatch from '../../components/listDispatch';
import { withRouter } from 'react-router'
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  ordersCountRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding : '30px 0px',
    background : '#fff'
  },
  root: {
    flexGrow: 1,
  },
  pagination : {
    float : 'right'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor : '#ffeee8',
    color: '#333',
  }
}));





class DispatchComponent extends React.Component {
    constructor() {
        super();
        

        this.state = {
            orderStatus : 'mum_north_mira_road',
            page : 0
        }
    }

    nextPage = () => {
        let { page } = this.state ;
        
        page = page + 1 ;

        this.setState({page});
    } 

    setPage = ( page = 1 ) => {
        
        page = page - 1 ;

        if(page >= 0 )
            this.setState({page});
    } 
    changePage = ( event , page ) => {
        
        this.setPage(page);

    }
    render(){
        
        const  { classes , zone }= this.props ;
        let { orderStatus , page} = this.state ;
        let orderZone = 'mum_north_mira_road';
        if(zone)
            orderZone = zone ;

        return (
            
            <div className={classes.root}>
                {/* <LinearProgress /> */}
                
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                            <Typography variant="h6" component="h6">
                                All Orders Of {zone}
                            </Typography>
                            </Grid>
                            <Grid item xs={6} justify="flex-end">
                                <Pagination count={page + 5} variant="outlined"  className={classes.pagnation} onChange={this.changePage} />
                            </Grid>
                        </Grid>
                    
                    </Grid>
                    <Grid item xs={12}>
                        <ListDispatch zone={orderZone} page={page} />
                    </Grid>
                </Grid>
                
            </div>  
        );
    }
  
}


function Dispatches( data ) {
    const classes = useStyles();
    // console.log(data.match.params);
    if(data.match.params.zone != undefined){
        console.log('Showing Componetn with status');
        return  <DispatchComponent classes={classes} zone={data.match.params.zone} />
    }
    return  <DispatchComponent classes={classes} />
    
}

export default withRouter(Dispatches);