import React, {useState} from "react";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import Button from '@material-ui/core/Button';
import { Grid, Typography } from "@material-ui/core";
import axios from 'axios';
const HTMLDecoderEncoder = require("html-encoder-decoder");


export default function SaveEmailTemplateButton( props ) {
  const {templateName , designString , htmlString } = props ;
  const [data, setdata] = useState('')

  const createEmailTemplate = async () => {
    console.log({templateName , designString , htmlString } );
    const emailReq = await axios.post(`https://pok-backend-y3hbx.ondigitalocean.app/api/v1/email_template/create`,{ data :  {templateName , designString , htmlString }});
    console.log({emailReq});
  }



  return (
    <React.Fragment>
        {data && <Typography> Template Saved! </Typography>}
        {!data &&  
        <Button variant="outlined" color="secondary" onClick={() => createEmailTemplate()}>
            Create Template
        </Button>}

    </React.Fragment>
  );
}