import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { gql, useQuery , useLazyQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom' ;
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({

  itemsRequired:{
      padding : '20px 0px'
  },
  customRangeInputForm : { 
    display : 'flex',
    justifyContent : 'space-around' ,
    maxWidth : '400px',
    margin : '0 auto',
    padding : 20
  }
}));

export default function DateRangeSummary( props ) {
  const classes = useStyles();

  let [afterDate, setAfterDate] = React.useState('');
  let [beforeDate, setBeforeDate] = React.useState('');
  

 
   
  const {  status , title } = props ;
  const GET_ORDERS = gql`
    query {
        dateRangeSummary {
            totalOrders
            codAmount
            prepaidAmount
            codOrders {
              id
              name
              total
              completed_date
            }
        }
    }
  `;

  const GET_ORDERS_RANGE = gql`
    query {
        dateRangeSummary ( range : "${afterDate + '|' + beforeDate}" ){
            totalOrders
            codAmount
            prepaidAmount
            codOrders {
              id
              name
              total
              completed_date
            }
        }
    }
  `;
  var error = '';
  var [
    fetchOrdersRange, 
    { loading, data }
  ] = useLazyQuery(GET_ORDERS_RANGE);

  
   var defaultData  = useQuery(GET_ORDERS);
   if(!data){
    loading = defaultData.loading;
    data = defaultData.data;
    error = defaultData.error;
  }

  

  if(  loading ){
    return(
      <Skeleton variant="rect" width={210} height={118} />
    )
  }

  if(error){
    console.log(error);
      return ( 
        <span>{error}</span>
      )
  }


  console.log({data});
  console.log({loading});



  

  const { codAmount, prepaidAmount ,totalOrders , codOrders } = data.dateRangeSummary ;
  

  
  console.log(beforeDate);
  console.log(afterDate);

  
  return (
    <div className={classes.root}>
        <Paper elevation={1} className={classes.itemsRequired} >
            <Grid container spacing={2} alignItems="center"> 
                <Grid item xs={12}>
                  <form className={classes.container} className={classes.customRangeInputForm}>
                    <TextField
                      id="fromDate"
                      label="From date"
                      type="date"
                      value={afterDate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={e => { console.log(e.target.value);  setAfterDate(e.target.value)}}
                    />
                    <TextField
                      id="toDate"
                      label="To date"
                      type="date"
                      value={beforeDate}
                      onChange={e => { console.log(e.target.value);  setBeforeDate(e.target.value)}}

                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Button onClick={() => fetchOrdersRange()} >Fetch</Button>
                  </form>
                </Grid>
                <Grid item xs={6} >
                

                  <Typography variant="h5" display="block" gutterBottom align="center" >
                    Total Orders Completed
                  </Typography>
                  <Typography variant="h3" display="block" gutterBottom align="center" >
                    {totalOrders}
                  </Typography>

                  <Grid container spacing={5}>
                    <Grid item xs={6} >
                        <Typography variant="h6" display="block" gutterBottom align="center" >
                            COD
                        </Typography>
                        <Typography variant="h5" display="block" gutterBottom align="center" >
                           ₹{codAmount}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6" display="block" gutterBottom align="center" >
                            Prepaid
                        </Typography>
                        <Typography variant="h5" display="block" gutterBottom align="center" >
                            ₹{prepaidAmount}
                        </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" display="block" gutterBottom align="center" >
                    COD Orders
                  </Typography>
                  
                  {codOrders.map((order) => (
                    <Paper  elevation={0} style={{padding : 5}} >
                      <Link to={`orders/${order.id}`}>
                        <ListItemText primary={`₹${order.total} - ${order.completed_date}` }  secondary={`#${order.id}  |  ${order.name}`}/>
                      </Link>
                    </Paper>
                  ))}
                </Grid>
            </Grid>
            
             
            
        </Paper>
    </div>
  );
}