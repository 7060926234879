import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { gql, useQuery } from '@apollo/client';
import MarkOutForDeliveryButton from './actions/markOutForDeliveryButton';
import BookCourierButton from './actions/bookCourierButton';
import CreatePaymentLinkButton from './actions/createPaymentLinkButton';
import MarkOrderPaidButton from './actions/markOrderPaidButton';
import DownloadLabel from './actions/downloadLabel';
const useStyles = makeStyles((theme) => ({

  orderCount:{
      padding : '15px'
  }
}));

export default function OrderActions( props ) {
  const classes = useStyles();

  const {  id , allowedActions } = props ;


  
  return (
    <div className={classes.root}>
        <Paper elevation={1} className={classes.orderCount} >
            
            <Typography variant="button" display="block" gutterBottom >
                Order Actions
            </Typography>

            <Divider style={{
                marginBottom:  15,
                marginTop : 15
            }} />

            <Grid container  spacing={12} >
            
              <Grid item xs={12} style={{ paddingBottom : 10 }}>
                {allowedActions.includes('mark_out_for_delivery') && <MarkOutForDeliveryButton id={id} style={{
                  marginBottom : '15px'
                }} /> }
              </Grid>

              <Grid item xs={12} style={{ paddingBottom : 10 }}>
                {allowedActions.includes('mark_out_for_delivery') && <BookCourierButton id={id} />}    
              </Grid>
              
              <Grid item xs={12} style={{ paddingBottom : 10 }}>
                {allowedActions.includes('mark_out_for_delivery') && <CreatePaymentLinkButton id={id} />}    
              </Grid>

              <Grid item xs={12} style={{ paddingBottom : 10 }}>
                {allowedActions.includes('mark_out_for_delivery') && <DownloadLabel id={id} />} 
              </Grid>
              <Grid item xs={12} style={{ paddingBottom : 10 }}>
                {allowedActions.includes('mark_out_for_delivery') && <MarkOrderPaidButton id={id} />}    
              </Grid>
            
            </Grid>
        </Paper>
    </div>
  );
}