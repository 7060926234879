import React from 'react';
import { ToastContainer, toast } from 'react-toastify';


export default class NotificationList extends React.Component{
    constructor ( props ){
        super( props )
    }

    componentDidMount(){
        // setInterval(() => {
        //     toast("Wow so easy!");
        // }, 3000);
    }

    render(){

        return (
            <div> 
                <h2> My Notificaiton List Component  </h2>
            </div>
        );
    }
}