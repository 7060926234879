import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import { gql, useQuery } from '@apollo/client';
import LeadListItem from '../leadListItem' ;

const useStyles = makeStyles((theme) => ({

  itemsRequired:{
      padding : '20px 0px'
  },
  pagination : {
    float : 'right'
  }
}));

export default function ListLeads( props ) {
  const classes = useStyles();

  const {  status , page } = props ;
  const GET_ORDERS = gql`
    query{
      leadsList(status : "${status}", page : ${page} ){
        source_id 
        name 
        mobile 
        email 
        status
        converted 
        tags
        created_at
      }
    }
  `;
  const { loading, error, data } = useQuery(GET_ORDERS);
  
  

  if( loading ){
    return(
      <Skeleton variant="rect" width={210} height={118} />
    )
  }

  if(error){
    console.log({error});
    
      return ( 
        <span> Error Occured </span>
      )
  }

  const { leadsList } = data ;
  

  
  return (
    <div>
      <Grid container>
        <Grid item xs={12}> 
        {leadsList.map((order) => (
          <LeadListItem orderDetails={order} />
        ))}
        </Grid>
      </Grid>
      </div>
  );
}