import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { gql, useQuery } from '@apollo/client';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({

  itemsRequired:{
      padding : '20px 0px'
  }
}));

export default function ItemsRequired( props ) {
  const classes = useStyles();

  const {  status , title } = props ;
  const GET_ORDERS = gql`
    query{
      itemsRequired ( status :"${status}" ){
        name 
        quantity
      }
    }
  `;
  const { loading, error, data } = useQuery(GET_ORDERS);
  
  if( loading ){
    return(
      <Skeleton variant="rect" width={210} height={118} />
    )
  }

  if(error){
      return ( 
        <span>{error}</span>
      )
  }

  const { itemsRequired } = data ;


  
  return (
    <div className={classes.root}>
        <Paper elevation={1} className={classes.itemsRequired} >
            
            <Typography variant="button" display="block" gutterBottom align="center" >
                {title}
            </Typography>
             
            <div style={{ display : 'flex', flexWrap : 'wrap' , justifyContent: 'center'}}> 
            {itemsRequired.map((item) => (
              <Paper  elevation={0} style={{ maxWidth : '50%' , width : '30%' , padding : 20}} >
                <ListItemText primary={`${item.quantity} Kg / Units` }  secondary={item.name}/>
              </Paper>
            ))}
            </div>
        </Paper>
    </div>
  );
}