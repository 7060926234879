import React from "react";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      backgroundColor : '#f9f9f9',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
      backgroundColor : '#f9f9f9',
    },
  }),
);

export default function MarkOutForDeliveryButton( props ) {
  const classes = useStyles();
  let [paidBy, setPaidBy] = React.useState('gpay_patilkaki');


  const { id } = props ;

  const markOrderPaid = async () => {
    
    console.log({ orderId : id  , paidVia : paidBy });
    

    const paymentLinkResponse = await axios.post(`https://pok-backend-y3hbx.ondigitalocean.app/api/v1/orders/paid`,{ data :  { orderId : id  , paidVia : paidBy }}).catch((error) => {
      console.log({error});
      this.sendNotification('Payment Link Creation Failed' , 'error' );
    });
    
    if(paymentLinkResponse){
      
      this.sendNotification('Payment Link Sent to Customer' );
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    

  }


  return (
    <React.Fragment>
      <FormControl variant="filled" className={classes.formControl} fullWidth>
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
          Paid Via
        </InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          className={classes.selectEmpty}
          onChange={(event) => setPaidBy(event.target.value) }
          value={paidBy}
          fullWidth 
        >
          <MenuItem value="gpay_patilkaki">GPay PATILKAKI</MenuItem>
          <MenuItem value="razorpay_patilkaki">RazorPay PATILKAKI</MenuItem>
          <MenuItem value="banktransfer_patilkaki">Bank Transfer PATILKAKI</MenuItem>
          <MenuItem value="gpay_vinit">GPay Vinit</MenuItem>
          <MenuItem value="paytm_vinit">Paytm Vinit</MenuItem>
          <MenuItem value="banktransfer_vinit">Bank Transfer Vinit</MenuItem>
          <MenuItem value="gpay_govinda">GPay Govinda</MenuItem>
          <MenuItem value="cash_govinda">Cash Govinda</MenuItem>
          <MenuItem value="gpay_darshil">GPay Darshil</MenuItem>
          <MenuItem value="cash_darshil">Cash Darshil</MenuItem>
          <MenuItem value="cash_deliveryBoy">Cash DeliveryBoy</MenuItem>
        </Select>
        <Button variant="outlined" color="secondary" fullWidth onClick={() => markOrderPaid()}>
            Mark Payment Received
        </Button>
      </FormControl>
    </React.Fragment>
  );
}