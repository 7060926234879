import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import { gql, useQuery } from '@apollo/client';
import CodListItem from '../codListItem' ;
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { Input } from '@material-ui/core';
import { Group } from '@material-ui/icons';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({

  itemsRequired:{
      padding : '20px 0px'
  },
  pagination : {
    float : 'right'
  }
}));


function hex2rgb(hex, opacity) {
  var h=hex.replace('#', '');
  h =  h.match(new RegExp('(.{'+h.length/3+'})', 'g'));

  for(var i=0; i<h.length; i++)
      h[i] = parseInt(h[i].length==1? h[i]+h[i]:h[i], 16);

  if (typeof opacity != 'undefined')  h.push(opacity);

  return 'rgba('+h.join(',')+')';
}

function AddOrderToGroup( props) {
  let { name , meta  , hasDeliveryBoyAssigned , deliveryBoy , status } = props.group ;

  let [orderId, setOrderId] = React.useState('');
  
  let defaultShowAssignForm = true ;

  if(hasDeliveryBoyAssigned)
    defaultShowAssignForm = false ;


  return (
    <Grid container>
      
      <Grid container>
        <Grid item xs={12}>
          <Input placeholder='Order Id' fullWidth  value={orderId} onChange={(e) => setOrderId(e.target.value)} />
        </Grid>
        <Grid item xs={12}>
          <Button  fullWidth  onClick={async () => {
              
            const paymentLinkResponse = await axios.post(
              `https://pok-backend-y3hbx.ondigitalocean.app/api/v1/groups/order/add`,
              { 
                data :  
                { name : name , orderId : orderId  }

              }).catch((error) => {
              console.log({error});
              alert('Failed To Add Order To Group');          
            });
            alert('Add Order To Group');          

          }}> ADD ORDER </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

function AsignDeliveryBoy( props) {
  let { name , meta  , hasDeliveryBoyAssigned , deliveryBoy , status } = props.group ;

  let [deliverBoyName, setDeliverBoyName] = React.useState('');
  let [deliverBoyNo, setDeliverBoyNo] = React.useState('');
  let defaultShowAssignForm = true ;

  if(hasDeliveryBoyAssigned)
    defaultShowAssignForm = false ;

  let [showAssignForm, setShowAssignFrom] = React.useState(defaultShowAssignForm);

  return (
    <Grid container>
      {hasDeliveryBoyAssigned && !showAssignForm && <div>
        <Typography variant='overline' fullWidth>Delivery By</Typography>
        <Typography variant='body1' >{deliveryBoy.name}</Typography>
        <Typography variant='body1' >{deliveryBoy.mobile}</Typography>
        <Typography variant='caption' onClick={() => {
          setShowAssignFrom(true);
        }}>Edit</Typography>
      </div>}
      {showAssignForm && <Grid container>
        <Grid item xs={12}>
          <Input placeholder='Name' fullWidth  value={deliverBoyName} onChange={(e) => setDeliverBoyName(e.target.value)} />
        </Grid>
        <Grid item xs={12}>
          <Input placeholder='Mobile No' fullWidth  value={deliverBoyNo} onChange={(e) => setDeliverBoyNo(e.target.value)}  />
        </Grid>
        <Grid item xs={12}>
          <Button  fullWidth  onClick={async () => {
              
            const paymentLinkResponse = await axios.post(
              `https://pok-backend-y3hbx.ondigitalocean.app/api/v1/groups/assign`,
              { 
                data :  
                { groupName : name , dboyName : deliverBoyName , dboyNo : deliverBoyNo  }

              }).catch((error) => {
              console.log({error});
              alert('Failed To Assign Delivery Boy');          
            });
            alert('Delivery Boy Assigned');          

          }}> Update Delivery Boy</Button>
        </Grid>
      </Grid>}
    </Grid>
  )
}
export default function ListGroups( props ) {
  const classes = useStyles();

  const {  status , page } = props ;
  console.log({status});
  const GET_ORDERS = gql`
    query{
      codgroups ( status : "${status}" , page : ${page} ) {
        name
        status
        codOrders
        hasDeliveryBoyAssigned
        createdAt
        createdAt_Formatted
        deliveryBoy {
          name 
          mobile
        }
        orders{
          id
          
          data {
            id 
            name
            status
            paymentMethod
            total
            phone
            city
            date
            postcode
            groupID 
            cod_received_via
          }
        }
      }
    }
  `;
  const { loading, error, data } = useQuery(GET_ORDERS);
  
  const [wefastEstimate , setWefastEstimate] = useState(0);
  const [activeWefastGroupId , setActiveWefastGroupId] = useState(0);
  const [calculatingWefastTotal , setCalculatingWefastTotal] = useState(false);

  if( loading ){
    return(
      <LinearProgress color='secondary' style={{  marginTop : 30 , marginBottom : 100 }}/>
    )
  }

  if(error){
      return ( 
        <span> Current Page {page} :: {error.message}</span>
      )
  }

  const { codgroups } = data ;
  

  return (
    <div>
      <Grid container>


        <Grid item xs={12}> 
        {codgroups.length < 1 && 
          <Typography variant='h6' align='center' >
             No Group's To Show Here
          </Typography>
        }
        {codgroups.map((group) => (
          <Grid container style={{ padding :  15 }} >
            <Grid item xs={12} style={{ backgroundColor : hex2rgb('#' + group.name, 0.2) , padding : 10, marginBottom: 20 }}>
              
              <Grid container>
                  <Grid item xs={2} >
                    <Typography> 
                      Group #{group.name}
                    </Typography>
                  </Grid>
                  <Grid  item xs={4}>
                    {group.createdAt_Formatted && <Typography> {group.createdAt_Formatted}</Typography>}
                  </Grid>
                  <Grid item xs={4}>
                      {group.deliveryBoy && 
                          <Typography style={{ textTransform : 'capitalize' , float : 'right'}} > {group.deliveryBoy.name} : {group.deliveryBoy.mobile}  </Typography> }
                  </Grid>
                  
              </Grid>
              
            </Grid>
          

          
            <Grid item xs={12} style={{  marginTop : 0 , marginBottom : 50  }}>
                <Grid container >
                    {group.codOrders < 1 && 
                      <Typography variant='caption' align='center' >
                        No COD Orders Present
                      </Typography>
                    }
                    {group.orders.map(order => (
                      <Grid item xs={12}  >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                {order.data && <CodListItem orderDetails={order.data} />}
                            </Grid>
                        </Grid>
                        
                      </Grid>
                    ))}
                </Grid>
            </Grid>
          </Grid>

        ))}
        </Grid>
      </Grid>
      </div>
  );
}