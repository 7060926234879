import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { gql, useQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({

  itemsRequired:{
      padding : '20px 0px'
  }
}));

export default function CODOrdersSummary( props ) {
  const classes = useStyles();

  const {  status , title } = props ;
  const GET_ORDERS = gql`
    query {
      outForDeliverySummary {
        totalCODAmount
        codOrders{
          id 
          name 
          amount
        }
      }
    }
  `;
  const { loading, error, data } = useQuery(GET_ORDERS);
  
  if( loading ){
    return(
      <Skeleton variant="rect" width={210} height={118} />
    )
  }

  if(error){
      return ( 
        <span>{error}</span>
      )
  }

  const { totalCODAmount , codOrders } = data.outForDeliverySummary ;


  
  return (
    <div className={classes.root}>
        <Paper elevation={1} className={classes.itemsRequired} >
            <Grid container spacing={2} alignItems="center"> 
                <Grid item xs={6} >
                  <Typography variant="h5" display="block" gutterBottom align="center" >
                    Total Amount
                  </Typography>
                  <Typography variant="h3" display="block" gutterBottom align="center" >
                    ₹{totalCODAmount}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {codOrders.map((order) => (
                    <Paper  elevation={0} style={{padding : 5}} >
                      <ListItemText primary={`₹${order.amount}` }  secondary={`#${order.id}  |  ${order.name}`}/>
                    </Paper>
                  ))}
                </Grid>
            </Grid>
            
             
            
        </Paper>
    </div>
  );
}