import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { gql, useQuery } from '@apollo/client';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const useStyles = makeStyles((theme) => ({
  orderCount:{
      padding : '15px'
  },
  divider : {
    marginBottom : '10px'
  },
  shipmentDetails : {

  }

}));

export default function OrderShipments( props ) {
  const classes = useStyles();

  const {  id  } = props ;

  const GET_ORDERS = gql`
    query {
        shipments ( id : ${id} ){
            id
            status
            awb_code
            shipment_id
            courier_name
            courier_company_id
            label_url
        }
    }  
  `;
  const { loading, error, data } = useQuery(GET_ORDERS);
  
  if( loading ){
    return(
      <Skeleton variant="rect" width={210} height={118} />
    )
  }

  if(error){
      return ( 
      <span>{error}</span>
      )
  }

  const { shipments } = data ;
  
  return (
    <div className={classes.root}>
        <Paper elevation={1} className={classes.orderCount} >
            
            <Typography variant="button" display="block" gutterBottom >
                Shipments For #{id}
            </Typography>
            <Divider className={classes.divider} />

            {!shipments && (
                <>
                    <Typography> No Shipments Found </Typography>
                </>
            )}

            {shipments && (
                <>
                    <Typography variant="overline" display="block" > Courier Name :  {shipments.courier_name} </Typography>
                    <Typography variant="overline" display="block" > AWB Code :  <a href={`https://shiprocket.co/tracking/${shipments.awb_code}`} rel="noreferrer" target='_blank'>{shipments.awb_code}</a> </Typography>
                    <Typography variant="overline" display="block" > Status :  {shipments.status} </Typography>
                    <Typography variant="overline" display="block" > Shipment ID :  {shipments.shipment_id} </Typography>
                    <ButtonGroup variant="text" size="small" color="primary" aria-label="text primary button group">
                      <Button>
                        <a href={`${shipments.label_url}`} target='_blank' rel="noreferrer"  >
                        Download Label
                        </a>
                      </Button>
                    </ButtonGroup>
                </>
            )}
            

        </Paper>
    </div>
  );
}