import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { gql, useQuery } from '@apollo/client';

const useStyles = makeStyles((theme) => ({

  orderCount:{
      padding : '10px 50px'
  }
}));

export default function OrderCount( props ) {
  const classes = useStyles();

  const {  status , title } = props ;
  const GET_ORDERS = gql`
    query {
        ordersCount ( status : "${status}" )
    }  
  `;
  const { loading, error, data } = useQuery(GET_ORDERS);
  
  if( loading ){
    return(
      <Skeleton variant="rect" width={210} height={118} />
    )
  }

  if(error){
      return ( 
      <span>{error}</span>
      )
  }

  const { ordersCount } = data ;


  
  return (
    <div className={classes.root}>
        <Paper elevation={0} className={classes.orderCount} >
            
            <Typography variant="button" display="block" gutterBottom >
                {title}
            </Typography>
             
            <Typography variant="h3" style={{
                textAlign : 'center'
            }} component="h3">
                {ordersCount}
            </Typography>
        
        </Paper>
    </div>
  );
}