import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import './App.css';
import React from 'react';
import Box from '@material-ui/core/Box';
import { BrowserRouter } from 'react-router-dom'
import { Switch, Route } from 'react-router-dom'
import Header from './layout/header' ;
import Dashboard from './pages/dashboard';
import Orders from './pages/orders';
import Leads from './pages/leads';
import Groups from './pages/groups';
import OrderPage from './pages/order';
import DispatchPage from './pages/dispatch';
import CreateOrderPage from './pages/createOrder';
import CreateShipmentGroupPage from './pages/createShipmentGroups';
import CreateEmailerPage from './pages/marketing/emailers/create';
import LoginPage from './login';
import ShipmentsPage from './pages/shipments';
import CodManagementPage from './pages/codManagement';

import {
  amber,
  lightGreen
} from '@material-ui/core/colors';

import { createMuiTheme , ThemeProvider} from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';

const theme = createMuiTheme({
  
  palette: {
    primary: amber,
    processing: amber,
  },
});


const client = new ApolloClient({
  uri: 'https://pok-backend-y3hbx.ondigitalocean.app/graphql',
  cache: new InMemoryCache()
});

function App() {

  const defaultLogged = localStorage.getItem("logged_in");

  const [ loggedIn , setLoggedIn ] = React.useState(defaultLogged);
  

  const makeUserLoggedin = ( state ) => {
    localStorage.setItem("logged_in", state)
    setLoggedIn(state);
  }
  return (
    <BrowserRouter>
      {!loggedIn && <LoginPage onLogin={makeUserLoggedin} />}

      {loggedIn && 
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>

          <Header />
          <Box m={15} marginTop={5} >
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/cod" component={CodManagementPage} />
              <Route exact path="/groups" component={Groups} />
              <Route exact path="/groups/status/:status" component={Groups} />
              <Route exact path="/orders" component={Orders} />
              <Route exact path="/orders/status/:status" component={Orders} />
              <Route exact path="/orders/new" component={CreateOrderPage} />
              <Route exact path="/orders/:id" component={OrderPage} />
              <Route exact path="/dispatch/create" component={CreateShipmentGroupPage} />
              <Route exact path="/dispatch/:zone" component={DispatchPage} />
              <Route exact path="/shipments" component={ShipmentsPage} />
              <Route exact path="/leads" component={Leads} />
              <Route exact path="/leads/status/:status" component={Leads} />
              <Route exact path="/marketing/emails/create" component={CreateEmailerPage} />
            </Switch>
          </Box>
        </ThemeProvider>
      </ApolloProvider>}
    </BrowserRouter>
  );
}

export default App;
