import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';


export default function LoginPage( props ) {
    const [ username , setUsername ] = React.useState(false);
    const [ password , setPassword ] = React.useState(false);

    
    const  onSubmit = (event) => {
        event.preventDefault();

        if(username === 'govinda' && password === 'patilkaka123')
            props.onLogin(true);
        else if(username === 'vinit' && password === 'JSI03@Vin')
            props.onLogin(true);
        else if(username === 'darsheel' && password === 'Darsheel@PKOMS@6654')
            props.onLogin(true);
        else
            alert('Incorrect Username & Password')
        
    }
  return (
    <React.Fragment>
        <Grid container spacing={2} justify="center" alignItems="center"  style={{ height : '100vh', backgroundColor : '#e1eded'}}>
            <Grid item xs={3}>
                <form  autoComplete="off" onSubmit={onSubmit}>
                    <Grid container spacing={2} style={{ backgroundColor : '#fff' , padding : 20}}>
                        <Grid item xs={12}>
                            <Typography variant="h6" alignItems="center" style={{ textAlign : 'center'}}>
                                <img src="https://i0.wp.com/patilkaki.com/wp-content/uploads/2020/10/pk-logo-min.png" alt="Logo" style={{ maxHeight : 70 }} className="img-responsive" />
                            </Typography>    
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="outlined-basic" required label="Username" variant="outlined" fullWidth
                                onInput={ e=>setUsername(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="outlined-basic" required label="Password" variant="outlined" fullWidth
                                onInput={ e=>setPassword(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" type="submit" fullWidth>Login</Button>
                        </Grid>
                    </Grid>        
                    
                </form>
            </Grid>
        </Grid>
    </React.Fragment>
  );
}
