
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ListItem from  '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { Link } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
import { gql, useQuery } from '@apollo/client';
import OrderListItem from '../orderListItem' ;



const useStyles = makeStyles((theme) => ({
    menuLink : {
        color : '#333' ,
        textDecoration : 'none',
        display : 'block',
        width : '100%'
    },
    searchResults : {
        position : 'absolute',
        background :'#fff'
    }
}));

  
export default function SearhResults( props ) {
    const classes = useStyles();
  
    let { searchString } = props ;


    const GET_ORDERS = gql`
      query{
        searchResults( searchString : "${searchString}" ){
            id
            name
        }
      }
    `;
    const { loading, error, data } = useQuery(GET_ORDERS);
    
    if( loading ){
      return (
        <React.Fragment>
            
        </React.Fragment>  
      );
    }
  
    if(error){
        return ( 
          <span>{error}</span>
        )
    }
  
    const { searchResults } = data ;
    
  
    
    return (
        <List style={{ minWidth : 250 }} className={classes.searchResults} >
            
            {searchResults.map((item) => (
                <ListItem button key={'dashboard'}>
                    <Link to={`/orders/${item.id}`} className={classes.menuLink}>
                        <ListItemText primary={`#${item.id}`} secondary={item.name} />
                    </Link>
                </ListItem>
            ))}        
            
        
    
        
        </List>
    );
  }