import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import { gql, useQuery } from '@apollo/client';
import ShipmentListItem from '../shipmentListItem' ;

const useStyles = makeStyles((theme) => ({

  itemsRequired:{
      padding : '20px 0px'
  },
  pagination : {
    float : 'right'
  }
}));

export default function ListShipments( props ) {
  const classes = useStyles();

  const {  status , page } = props ;
  const GET_ORDERS = gql`
    query{
      shipmentsList( status : "${status}" , page : ${page} ){
        id
        woo_id
        order_id
        shipment_id
        status
        status_code
        onboarding_completed_now
        awb_code
        courier_company_id
        courier_name 
        charges {
          freight_charges 
          cod_charges
        }
        label_url
      }  
    }

    
  `;
  const { loading, error, data } = useQuery(GET_ORDERS);
  
  if( loading ){
    return(
      <Skeleton variant="rect" width={210} height={118} />
    )
  }

  if(error){
      return ( 
        <pre>{JSON.stringify(error)}</pre>
      )
  }

  const { shipmentsList } = data ;
  

  
  return (
    <div>
      <Grid container>
        <Grid item xs={12}> 
        {shipmentsList.map((order) => (
          <ShipmentListItem orderDetails={order} />
        ))}
        </Grid>
      </Grid>
      </div>
  );
}