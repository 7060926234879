import React from "react";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import Button from '@material-ui/core/Button';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import Divider from '@material-ui/core/Divider';
import MuiAlert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default class CreatePaymentLinkButton extends React.Component {
  constructor ( props ){
    super(props);

    this.state = {
      notificationOpen : false ,
      notificationType : 'success',
      notificationMessage : 'Payment Link Sent to Customer',
      notifyCustomer : true
    }
  }
  sendNotification = ( message = 'Action Successfull', type = 'success') => {
    this.setState({
      notificationOpen : true ,
      notificationType : type,
      notificationMessage : message 
    });
  } 
  createPaymentLink = async () => {
    const { id } = this.props ; 
    const { notifyCustomer } = this.state ; 
    
    

    const paymentLinkResponse = await axios.post(`https://pok-backend-y3hbx.ondigitalocean.app/api/v1/orders/payment`,{ data :  { orderId : id  , notify : notifyCustomer }}).catch((error) => {
      console.log({error});
      this.sendNotification('Payment Link Creation Failed' , 'error' );
    });
    
    if(paymentLinkResponse){
      
      this.sendNotification('Payment Link Sent to Customer' );
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    

  }

  handleClose = () =>{
    this.setState({
      notificationOpen : false 
    })
  }

  handleChange = ( event ) =>{
    console.log({ event });
    console.log(event.target.checked);
    this.setState({
      notifyCustomer : event.target.checked
    })
  }
  render(){
    const {notifyCustomer, notificationOpen , notificationType , notificationMessage} = this.state ;
    
    return (
      <React.Fragment>

          <Divider variant="middle" />

          <p></p>
          <Snackbar open={notificationOpen} autoHideDuration={1000}  >
            <Alert onClose={() => { console.log('call 2'); this.handleClose()}} severity={notificationType}>
              {notificationMessage}
            </Alert>
          </Snackbar>

          <Button variant="outlined" color="secondary"  disableElevation fullWidth onClick={ () => this.createPaymentLink()} >
              Create Payment Link
          </Button>
          
          <FormControlLabel
            control={<Checkbox checked={notifyCustomer} onChange={this.handleChange} />}
            label="Notify Customer"
          />

      </React.Fragment>
    );
  }
}