import React, { useState, useEffect } from 'react';
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import { Input, TextField, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
const YOUR_GOOGLE_MAPS_API_KEY =  'AIzaSyBxgIpowUZNM5vykLF0NJTCKPa2Yjer7MM';
const useStyles = makeStyles((theme) => ({
    modalContainer : {
        margin : 50 ,
        backgroundColor : '#fff',
        maxWidth : 800 , 
        padding : 30 ,
        borderRadius : 10 ,
        overflow : 'hidden'
    },
    suggestionItem :{
        display :'block',
        width : '100%',
        maxWidth : '100%',
        padding : '10px 15px',
        borderBottom : '1px solid #eee',
        "&:hover, &:focus": {
            backgroundColor : '#eee',
            cursor : 'pointer'
        }
    }
}));


function AutoCompleteInput ( { onSelectPlace } ) {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: YOUR_GOOGLE_MAPS_API_KEY,
    options : {
        types : [],
        componentRestrictions : {
            country : 'in'
        }
    }
  });
  const classes = useStyles();


  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    if (placePredictions.length)
    placesService.placesService?.getDetails(
        {
          placeId: placePredictions[0].place_id,
        },
        (placeDetails) => console.log(placeDetails)
      );
  }, [placePredictions]);

  return (
    <>
      <TextField
        placeholder="Enter Place "
        onChange={(evt) => {
          getPlacePredictions({ input: evt.target.value });
        }}
        style={{
            width : '100%'
        }}
        loading={isPlacePredictionsLoading}
      />
      {placePredictions.map((item) => <Typography onClick={() => onSelectPlace(item) } className={classes.suggestionItem} variant='button' > {item.description}</Typography> )}
    </>
  );
};
// 'AIzaSyC-nQ1t--VdXbuOX06O_dGQAWiAtChVsdc';
// place_id:ChIJiTsoyqvI5zsRRfZNZ0ZFVBs
export default function SetLocation({ orderId }){
    

    const [ open, setOpen ] = useState(false);
    const [ refreshing, setRefreshing ] = useState(false);

    const classes = useStyles();
    const { ref: materialRef  } = usePlacesWidget({
        apiKey:  YOUR_GOOGLE_MAPS_API_KEY ,
        onPlaceSelected: (place, inputRef, autocomplete) => console.log(autocomplete),
        inputAutocompleteValue: "country",
        options: {
          types: [],
          componentRestrictions: { country :  'in' }
        },
    });


    const GET_ORDERS = gql`
        query {
            getPlaceDetails ( id : ${orderId} ){
                status
                formattedAddress
            }
        }  
    `;
    const { loading, error, data , refetch} = useQuery(GET_ORDERS, {
        fetchPolicy: "no-cache"
      });


    
    const onSelectPlace = async ( place ) => {

        setRefreshing(true);
        await axios.post(`https://pok-backend-y3hbx.ondigitalocean.app/api/v1/orders/set-location`,{ data :  { placeId : place.place_id , orderId : orderId  }}).catch((error) => {
            console.log({error});
        });
        setTimeout(async () => {
            await refetch();
            handleClose();
            setRefreshing(false);
        }, 2000);
    }
    
    if( loading || refreshing ){
        return(
            <Skeleton variant="rect" width={'100%'} height={20} />
        )
    }

    if(error){
        return ( 
            <span>{error.message}</span>
        )
    }

    const { getPlaceDetails } =  data ;



    const handleClose = () => {
        setOpen(false);
    }

  

    
    return (
        <Grid container >
            
            <Grid item xs={12}>
                <Modal
                    open={open}
                    onClose={handleClose}
                    style={{
                        display : 'flex',
                        justifyContent : 'center',
                        alignItems : 'center'
                    }}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Grid container className={classes.modalContainer} style={{ width : '100%' , backgroundColor : '#fff' }} >
                        <Grid item xs={12}>
                            <AutoCompleteInput  onSelectPlace={onSelectPlace} />
                        </Grid>
                        <Grid item xs={12}>
                        
                        </Grid>
                    </Grid>
                    
                </Modal>
            </Grid>
            {getPlaceDetails.status == 'NOT_ASSIGNED' && <Grid item xs={12}>
                <Button onClick={() => setOpen(!open)} > ASSIGN LOCATION </Button>
            </Grid>}

            {getPlaceDetails.status == 'ASSIGNED' && <Grid item xs={12}>
                <Typography variant='caption' > {getPlaceDetails.formattedAddress} <EditIcon style={{ fontSize : 10 , cursor : 'pointer'}} onClick={() => setOpen(!open)} /> </Typography>
            </Grid>}
        </Grid>
        
    )
}