import React, { useRef } from 'react';
import { render } from 'react-dom'
import EmailEditor from 'react-email-editor';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import SaveEmailTemplateButton  from '../../../../components/saveEmailTemplateButton';

const CreateEmailPage = (props) => {
  const emailEditorRef = useRef(null);
  const [templateName, setTemplateName] = React.useState('');
  const [designString, setDesignString] = React.useState('');
  const [htmlString, setHtmlString] = React.useState('');


  const onSave = () => {
    if(!templateName){
        alert('Please Enter a Template Name Before Saving');
        return;
    }

    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      
      console.log({design});
      var designString = JSON.stringify(design);
      // var htmlString = JSON.stringify(html);
      setDesignString(designString);
      setHtmlString(html);
      // console.log({designString}, {htmlString}, {templateName});
    });

    
  };

  const onLoad = () => {
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
  };

  return (
    <Grid>
      
      <Grid item xs={12}>
        <Paper style={{ display : 'flex' , alignItems : 'center', justifyContent : 'space-between', padding : '20px'}}>
            <Typography variant="h6">
                Create New Template
            </Typography>
            <div style={{ display : 'flex' , alignItems : 'center'}} >
  
                <TextField id="template-name" label="Template Name" variant="outlined" value={templateName} onChange={(e) => setTemplateName(e.target.value) } style={{ marginRight : 20 }} />
                
                {!designString && <Button onClick={onSave} variant="contained" color="secondary" > Save It!</Button>}
                {designString && htmlString && <SaveEmailTemplateButton templateName={templateName} htmlString={htmlString} designString={designString} />}

            </div>
        </Paper>        
        
      </Grid>
      <Grid item xs={12}>
        <EmailEditor
            ref={emailEditorRef}
            onLoad={onLoad}
        />
      </Grid>
      
    </Grid>
  );
};

export default CreateEmailPage ;