import React from "react";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import Button from '@material-ui/core/Button';



export default function MarkOutForDeliveryButton( props ) {
  
  const { id } = props ;
  const MARK_ORDER_OUT = gql`
    query {
        markOutforDelivery ( id : ${id} ){
            status
        }
    }
`;

  const [
    markOrderOutForDeliveryByUs, 
    { loading, data }
  ] = useLazyQuery(MARK_ORDER_OUT);

  if (loading) return <p>Loading ...</p>;
  if (data && data.markOutforDelivery) {
    console.log(data.markOutforDelivery);
  }

  return (
    <React.Fragment>
        {!data &&  
        <Button variant="outlined" color="secondary" fullWidth onClick={() => markOrderOutForDeliveryByUs()}>
            Mark Out For Delivery
        </Button>}

    </React.Fragment>
  );
}