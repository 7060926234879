import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Badge from '@material-ui/core/Badge'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import { format } from 'date-fns';
import Select from '@material-ui/core/Select';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({

    OrderListItem:{
        padding : '10px 15px',
        marginBottom : '10px',
        marginTop : '10px'
    },
    OrderListItemCodFail : {
        padding : '10px 15px',
        marginBottom : '10px',
        marginTop : '10px',
        background : '#ffeceb'
    },
    orderItemsContainer : {
        borderTop : '1px solid #f9f9f9'
    },
    customerNotesContainer : {
        borderTop : '1px solid #f9f9f9',
        background : '#FFFFE0'
    },
    orderItemsText : {
        fontSize : 13
    },
    chip : {
        textTransform : 'uppercase',
        padding : '2px 10px',
        borderRadius : 15
    },
    orderStatus : {
        textTransform : 'uppercase',
        whiteSpace : 'nowrap',
    }

}));

export default function DispatchListItem( props ) {
  const classes = useStyles();

  const { dispatchItemDetails } = props ;
  
  const  { woo_id , zone , extra } = dispatchItemDetails ;
  
  var paymentMethodTitle = extra.paymentMethod;
  if(extra.paymentMethod == 'cod')
    paymentMethodTitle = extra.paymentMethod + ' ' + extra.paidVia;

  const handleChange = async (event) => {
    var val  = event.target.value ;
    let status = val.split("|")[0];
    let order_id = val.split("|")[1];
    console.log({status}, {order_id});


    await axios.post(`https://pok-backend-y3hbx.ondigitalocean.app/api/v1/orders/status`,{ data :  { orderId : order_id , status : status }}).catch((error) => {
      console.log({error});
    });
  
  }


  var codNonPaid = false ;
  if(extra.paymentMethod == 'cod'){
    console.log({extra});
    if(extra.paidVia == 'not_received'){
        codNonPaid = true;
    }
  }

  return (
    

        <Paper elevation={0} className={ classes.OrderListItem } style={{background : codNonPaid && '#fff0f0'  }} >
            <Badge color="secondary" badgeContent={extra.status} className={classes.orderStatus} ></Badge>
            <Grid container spacing={3} >
                <Grid item xs={12}>
                    
                    <Grid container spacing={1} alignItems="center" >
                        <Grid item xs={1}>
                            <Checkbox  />
                        </Grid>
                        <Grid item xs={2} alignItems="center" direction="row">
                            <Link to={`/orders/${woo_id}`} >
                            <Typography > #{woo_id} </Typography>
                            <Typography > {extra.name} </Typography>
                            {/* <Typography > {zone} </Typography> */}
                            </Link>
                        </Grid>
                        <Grid item xs={3} alignItems="center" direction="row">
                            <Typography > {extra.pincode} </Typography>
                            <Typography> {extra.deliveryPreference}</Typography>
                        </Grid>
                        <Grid item xs={2} alignItems="center" direction="row">
                        <Select
                            id={"order-status-change-" + woo_id}
                            defaultValue={extra.status +'|' + woo_id  }
                            onChange={handleChange}
                        >
                            <MenuItem value={'cancelled|' + woo_id}>Cancelled</MenuItem>
                            <MenuItem value={'on-hold|' + woo_id }>On Hold</MenuItem>
                            <MenuItem value={'processing|' + woo_id}>Processing </MenuItem>
                            <MenuItem value={'printed|' + woo_id}>Printed </MenuItem>
                            <MenuItem value={'packed|' + woo_id}>Packed</MenuItem>
                            <MenuItem value={'added-to-bag|' + woo_id}>Added To Bag</MenuItem>
                            <MenuItem value={'out-for-delivery|' + woo_id}>Out For Delivery</MenuItem>
                            {!codNonPaid && <MenuItem value={'completed|' + woo_id}>Completed</MenuItem>}
                        </Select>
                        </Grid>
                        <Grid item xs={2} alignItems="center" direction="row">
                            
                            <Typography > ₹{extra.total} </Typography>
                            <Chip label={paymentMethodTitle} className={classes.chip} />
                            {/* <Typography > {customFormatDate} </Typography> */}
                        </Grid>
                        <Grid item xs={2} alignItems="center" direction="row">
                            <a href={"https://pok-backend-y3hbx.ondigitalocean.app/api/v1/orders/label/" + woo_id } target='_blank'>
                                <Button variant="outlined"  >
                                    Download Label
                                </Button>
                            </a> 
                        </Grid>
                    </Grid>
                    
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="caption"> #{extra.address} </Typography>
                </Grid>
                <Grid item xs={12} className={classes.orderItemsContainer}  >
                    {extra.orderItems.map(orderItem => {

                        const { name , quantity } = orderItem ;
                        
                        return(
                            <Grid container spacing={1} key={name} alignItems="center"  >
                                <Grid item xs={1} alignItems="center" justify="end" direction="row">
                                    
                                </Grid>
                                <Grid item xs={4} alignItems="center" justify="end" direction="row" style={{ backgroundColor : quantity > 1 ? '#FCF4A2' : '#fff' , lineHeight : 2 }}>
                                    <Typography className={classes.orderItemsText} > {name} </Typography>
                                </Grid>
                                {quantity > 1 && <Grid item xs={3} alignItems="center" direction="row">
                                    <Typography className={classes.orderItemsText} > x {quantity} </Typography>
                                </Grid>}
                            </Grid>
                        )  
                    })}
                    
                </Grid>

                {extra.customer_note && <Grid item xs={12} className={classes.customerNotesContainer} >                
                    <strong>{extra.customer_note}</strong>    
                </Grid>}
                
            </Grid> 
        </Paper>
    
  );
}