import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import { gql, useQuery } from '@apollo/client';
import DispatchListItem from '../dispatchListItem' ;

const useStyles = makeStyles((theme) => ({

  itemsRequired:{
      padding : '20px 0px'
  },
  pagination : {
    float : 'right'
  }
}));

export default function ListDispatch( props ) {
  const classes = useStyles();

  const {  zone , page } = props ;
  console.log({zone});
  const GET_ORDERS = gql`
    query{
      orderZones ( zone :"${zone}" , page : ${page} ){
        woo_id
        zone
        extra {
          status
          total
          paymentMethod
          name
          pincode
          address
          customer_note
          deliveryPreference
          paidVia
          orderItems{
            name
            quantity
          }
        }
      }
    }
  `;
  const { loading, error, data } = useQuery(GET_ORDERS);
  
  if( loading ){
    return(
      <Skeleton variant="rect" width={210} height={118} />
    )
  }

  if(error){
      return ( 
        <span>{error.message}</span>
      )
  }

  const { orderZones } = data ;
  

  
  return (
    <div>
      <Grid container>
        <Grid item xs={12}> 
        {orderZones.map((dispatchZone) => (
          <DispatchListItem dispatchItemDetails={dispatchZone} />
        ))}
        </Grid>
      </Grid>
      </div>
  );
}