import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Badge from '@material-ui/core/Badge'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({

    OrderListItem:{
        padding : '10px 15px',
        marginBottom : '10px',
        marginTop : '10px'
    },
    orderItemsContainer : {
        borderTop : '1px solid #f9f9f9'
    },
    orderItemsText : {
        fontSize : 13
    },
    chip : {
        textTransform : 'uppercase',
        padding : '2px 10px',
        borderRadius : 15
    },
    orderStatus : {
        textTransform : 'uppercase',
        whiteSpace : 'nowrap',
    }

}));

export default function LeadListItem( props ) {
  const classes = useStyles();

  const { orderDetails } = props ;
  console.log(orderDetails);
  const  { source_id ,
    name ,
    mobile ,
    email ,
    status,
    tags, 
    created_at } = orderDetails ;

  let mobileFormatted = mobile.replace('+91','').replace(' ', '') ;

    const customFormatCreatedAt = format(new Date(created_at), 'MMM do hh:mm aaa') ;
  
  return (
    
        <Paper elevation={0} className={classes.OrderListItem} >
            <Badge color="secondary" badgeContent={customFormatCreatedAt} className={classes.orderStatus} ></Badge>
            <Grid container spacing={3} >
                <Grid item xs={12}>
                    
                    <Grid container spacing={1} alignItems="center" >
                        <Grid item xs={1}>
                            <Checkbox />
                        </Grid>
                        <Grid item xs={2} alignItems="center" direction="row">
                            
                            <Typography > {name} </Typography>
                            
                            
                        </Grid>
                        <Grid item xs={2}>
                            <Typography > {status} </Typography>
                        </Grid>
                        <Grid item xs={3} alignItems="center" direction="row">
                            <Chip label={tags} className={classes.chip} />
                        </Grid>
                        <Grid item xs={2} alignItems="center" direction="row">
                            <Typography > {email} </Typography>
                            <a target='_blank' href={'https://web.whatsapp.com/send?phone=+91' +  mobileFormatted } >
                                <Typography style={{ display : 'flex' , alignItems : 'center'}}> 
                                    <img src='https://img.icons8.com/color/452/whatsapp--v1.png' style={{ width : 20 ,  height : 'auto'}} /> 
                                    {mobileFormatted} 
                                </Typography>
                            </a>
                        </Grid>
                        <Grid item xs={2} alignItems="center" direction="row">
                            
                        </Grid>
                        
                    </Grid>
                    
                </Grid>

            </Grid> 
        </Paper>
    
  );
}