import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import OrderCount from '../../components/orderCount';
import ListGroups from '../../components/listGroups';
import { withRouter } from 'react-router'
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles((theme) => ({
  ordersCountRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding : '30px 0px',
    background : '#fff'
  },
  root: {
    flexGrow: 1,
  },
  pagination : {
    float : 'right'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor : '#ffeee8',
    color: '#333',
  },
  paper :{
    padding : 15, 
    backgroundColor : '#fff',
    width: 400,
    border: 'none'
  },
  modal : {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none'

  }
}));





class GroupsComponent extends React.Component {
    constructor() {
        super();
        

        this.state = {
            orderStatus : 'all',
            page : 0,
            activeGroup : '1234',
            showAssignDeliveryBoy : false
        }
    }

    nextPage = () => {
        let { page } = this.state ;
        
        page = page + 1 ;

        this.setState({page});
    } 

    setPage = ( page = 1 ) => {
        
        page = page - 1 ;

        if(page >= 0 )
            this.setState({page});
    } 
    changePage = ( event , page ) => {
        
        this.setPage(page);

    }
    render(){
        
        const  { classes , status }= this.props ;
        let { orderStatus , page , activeGroup }  = this.state ;

        console.log({status});
        if(status)
            orderStatus = status ;

        return (
            
            <div className={classes.root}>
                {/* <LinearProgress /> */}
                
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                            <Typography variant="h6" component="h6">
                                All Groups
                            </Typography>
                            </Grid>
                            <Grid item xs={6} justify="flex-end">
                                <Pagination count={page + 5} variant="outlined"  className={classes.pagnation} onChange={this.changePage} />
                            </Grid>
                        </Grid>
                    
                    </Grid>
                    <Grid item xs={12}>
                        <ListGroups status={orderStatus} page={page} />
                    </Grid>
                </Grid>
                <Modal 
                    open={false}
                    onClose={() => {}}
                    aria-labelledby="Assign Delivery Boy"
                    aria-describedby="Group #1235"
                    className={classes.modal}
                >
                    <div className={classes.paper}>
                        <Typography variant='h6' style={{ textTransform : 'uppercase' , textAlign : 'center'}} > Assign Delivery Boy - #{activeGroup} </Typography>

                        <Button> Update </Button>
                    </div>
                    
                </Modal>
                
            </div>  
        );
    }
  
}


function Groups( data ) {
    const classes = useStyles();
    console.log(data.match.params);
    if(data.match.params.status != undefined){
        console.log('Showing Componetn with status');
        return  <GroupsComponent classes={classes} status={data.match.params.status} />
    }
    return  <GroupsComponent classes={classes} />
    
}

export default withRouter(Groups);