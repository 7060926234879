import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Skeleton from '@material-ui/lab/Skeleton';
import { gql, useQuery } from '@apollo/client';
import { Badge } from '@material-ui/core';
import OrderActions from '../../components/orderActions' ;
import OrderShipments from '../../components/orderShipments' ;
import OrderPayments from '../../components/orderPayments' ;
import { format } from 'date-fns';


const useStyles = makeStyles((theme) => ({

  orderCount:{
      padding : '10px 50px'
  },
  orderInfo : {
      padding : '15px',
      paddingTop : '30px',
      marginBottom : 20
  },
  orderStatusBadge : {
      textTransform : 'uppercase',
      whiteSpace : 'nowrap'
  },
  orderContactDetails : {
    float : 'right'
  },
  orderPaymentMethod : {
      textTransform : 'uppercase'
  },
  orderBillingDetails : {
      padding : '15px'
  },
  bottomSpacing : {
      paddingBottom : '15px'
  }
}));

export default function OrderDetails( props ) {
  const classes = useStyles();

  const { orderId } = props ;
  const GET_ORDERS = gql`
    query {
        order ( id : ${orderId} ) {
            id
            status
            date
            payment_method
            customer_note
            total
            allowedActions
            shipping_lines {
                method_title
                method_id
            }
            billing {
              first_name
              last_name
              email
              phone
              address_1
              address_2
              city
              postcode
              state
              country
            }
            shipping {
              first_name
              last_name
              address_1
              address_2
              city
              postcode
              state
              country
            }
            line_items {
              name
              quantity
              total
            }
            groupId
            deliveryBoy {
                name 
                mobile
            }
        }
    }
  `;
  const { loading, error, data } = useQuery(GET_ORDERS);
  
  if( loading ){
    return(
      <Skeleton variant="rect" width={210} height={118} />
    )
  }

  if(error){
      return ( 
      <span>{error.message}</span>
      )
  }

  const { order } = data ;

  console.log({order});
  const { billing , status , shipping , line_items ,date,  shipping_lines , allowedActions } = order ;
  let mobileFormatted = billing.phone.replace('+91','').replace(' ', '') ;

  const customFormatDate = format(new Date(date), 'MMM do hh:mm aaa') ;
  
  return (
    <div className={classes.root}>

        <Grid container  spacing={2} >

            <Grid item xs={9}>
                <Grid container >
                    <Grid item xs={12}>
                        <Badge badgeContent={status} color="secondary" className={classes.orderStatusBadge} />   
                        <Paper elevation={1} className={classes.orderInfo} >
                            
                            <Grid container >
                                <Grid item xs={2}>
                                    <Typography variant="button" display="block" gutterBottom >
                                        {`#${order.id}`} 
                                    </Typography>
                                    <Typography variant="h6" display="block" gutterBottom >
                                        {`${billing.first_name} ${billing.last_name}`} 
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Chip label={order.payment_method} className={classes.orderPaymentMethod}  />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>
                                        ₹{order.total}
                                    </Typography>
                                    <Typography>
                                        {order.billing.city}
                                    </Typography>
                                    <Typography>
                                        {customFormatDate}
                                    </Typography>
                                </Grid>

                                <Grid item xs={2} className={classes.orderContactDetails} >
                                    
                                    <a target='_blank' href={'https://web.whatsapp.com/send?phone=+91' +  mobileFormatted } >
                                        <Typography style={{ display : 'flex' , alignItems : 'center'}}> 
                                            <img src='https://img.icons8.com/color/452/whatsapp--v1.png' style={{ width : 20 ,  height : 'auto'}} /> 
                                            {mobileFormatted} 
                                        </Typography>
                                    </a>
                                    <Typography variant="button" display="block" gutterBottom >
                                        {billing.email}
                                    </Typography>
                                </Grid>
                            </Grid>

                            
                            
                            
                        </Paper>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Paper elevation={1} className={classes.orderInfo} >
                            
                            <Grid container >
                                <Grid item xs={2}>
                                    
                                </Grid>
                                <Grid item xs={2}>
                                    {shipping_lines.map(item => 
                                        <Chip label={item.method_title} className={classes.orderPaymentMethod}  />
                                    )}
                                    
                                </Grid>
                                <Grid item xs={2}>
                                    
                                </Grid>
                                <Grid item xs={2}>
                                   
                                </Grid>

                                <Grid item xs={2} className={classes.orderContactDetails} >
                                    
                                </Grid>
                            </Grid>

                            
                            
                            
                        </Paper>
                    </Grid>
                
                    <Grid item xs={12}>
                        <Paper elevation={1} >
                            <Grid container > 
                                <Grid item xs={4}>
                                    <Paper elevation={0}  className={classes.orderBillingDetails} >
                                        <Typography variant="button" display="block" gutterBottom >
                                            Billing Details
                                        </Typography>
                                        <Typography variant="body2" display="block" gutterBottom >
                                            {`${billing.first_name} ${billing.last_name}`} 
                                        </Typography>
                                        
                                        <Typography variant="body2" display="block" gutterBottom >
                                            {billing.address_1}
                                        </Typography>
                                        <Typography variant="body2" display="block" gutterBottom >
                                            {billing.address_2}
                                        </Typography>
                                        <Typography variant="body2" display="block" gutterBottom >
                                            {billing.city}
                                        </Typography>
                                        <Typography variant="body2" display="block" gutterBottom >
                                            {billing.postcode}
                                        </Typography>
                                        <Typography variant="body2" display="block" gutterBottom >
                                            {`${billing.state}, ${billing.country}`}
                                        </Typography>
                                    </Paper>
                                </Grid>
                                
                                <Grid item xs={4}>
                                    <Paper elevation={0} className={classes.orderBillingDetails} >
                                        <Typography variant="button" display="block" gutterBottom >
                                            Shipping Details
                                        </Typography>
                                        <Typography variant="body2" display="block" gutterBottom >
                                            {`${shipping.first_name} ${shipping.last_name}`} 
                                        </Typography>
                                        <Typography variant="body2" display="block" gutterBottom >
                                            {shipping.address_1}
                                        </Typography>
                                        <Typography variant="body2" display="block" gutterBottom >
                                            {shipping.address_2}
                                        </Typography>
                                        <Typography variant="body2" display="block" gutterBottom >
                                            {shipping.city}
                                        </Typography>
                                        <Typography variant="body2" display="block" gutterBottom >
                                            {shipping.postcode}
                                        </Typography>
                                        <Typography variant="body2" display="block" gutterBottom >
                                            {`${shipping.state}, ${shipping.country}`}
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={4}>
                                    <Paper elevation={0} className={classes.orderBillingDetails} >
                                        <Typography variant="button" display="block" gutterBottom >
                                            Customer Notes
                                        </Typography>
                                        <Typography variant="body2" display="block" gutterBottom >
                                            {order.customer_note} 
                                        </Typography>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container > 
                            <Grid item xs={12}>
                                <Paper elevation={1}  className={classes.orderBillingDetails} >
                                    <Typography variant="button" display="block" gutterBottom >
                                        Order Items
                                    </Typography>
                                    

                                    {line_items.map(orderitem => (
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <Typography variant="body2" display="block" gutterBottom >
                                                    {orderitem.name}
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body2" display="block" gutterBottom >
                                                x {orderitem.quantity}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body2" display="block" gutterBottom >
                                                    ₹{orderitem.total}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ))}
                                    
                                </Paper>
                            </Grid>

                        </Grid>
                        
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={3} >
                {order.groupId && 
                <Paper elevation={1} style={{ padding : '15px' }} >
                <Grid container>
                    <Grid item xs={12}>
                            <Typography variant="button" display="block" gutterBottom >
                                Group Details #{order.groupId}
                            </Typography>
                            {order.deliveryBoy && 
                            <Grid item xs={12}>
                                <Typography variant="overline" display="block"  >
                                    Name : {order.deliveryBoy.name}
                                </Typography>
                                <Typography variant="overline" display="block" gutterBottom >
                                    Mobile : {order.deliveryBoy.mobile}
                                </Typography>
                            </Grid>}
                    </Grid>
                </Grid>
                </Paper>}
                <OrderPayments id={order.id} className={classes.bottomSpacing} />
                <OrderShipments id={order.id} className={classes.bottomSpacing} />
                <OrderActions id={order.id} allowedActions={allowedActions} className={classes.bottomSpacing}/>
            </Grid>
        </Grid>
    
    </div>
  );
}